import React,{useEffect, useState, Component} from 'react';
import Profile from './Profile';
import LinkedFeed from './LinkedFeed';
import LinkedNews from './LinkedNews';
import Account from '../../Images/img/account2.png'
import Image from '../../Images/img/image.png'
import Eventt from '../../Images/img/kalender.png'
import Post from '../../Images/img/post.png'
import '../BodyStyle/styles.scss'


function Body() {


  useEffect(()=>{

  
  
},[])




  return (
    <div className="LinkedBody">
      <Profile/>
      <div className='FeedBlock'>
      <div className='FeedHead'>
        <div className='FeedHeadShareBoxEntry'>
          <div className='FeedHeAudi1AdImg'><img src={Account}/></div>
          <div className='FeedHeadTextField'>Beitrag beginnen</div>
        </div>
        <div className='FeedHeadShareBox'>
          <div className='FeedHeadIcon'><img src={Image}/><span>Medieninhalten</span></div>
          <div className='FeedHeadIcon'><img src={Eventt}/><span>Event</span></div>
          <div className='FeedHeadIcon'><img src={Post}/><span>Artikel schreiben</span></div>
     
        </div>
      </div>
   
     <div id="ax300"> <hr id='ax400'/>Feed-Ansicht auswählen: <span>Relevanteste zuerst ▼</span></div>

      <LinkedFeed/>
      </div>
      <LinkedNews/>
   
  
    
     </div>

  );
}


  


export default Body;
