import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Img1 from '../assets/img/1.jpg'
import Img2 from '../assets/img/2.jpg'
import './styles.scss'


function ArtikelHighLight3Mob({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
        
        <div className='chef-hightlight_article3_container'>

<div className='chef-hightlight_article3'>
<div className='chef-hightlight_article3-img_container'><img src={Img1}/></div>

</div> 
</div>



<div className='chef-hightlight_article3-left'>
    <div className='chef-hightlight_article3-title'>einfache rezepte</div>
    <div className='chef-hightlight_article3-text'>Was Koche ich heute</div>
</div>

<div className='chef-hightlight_article3-right'>
    <div className='chef-hightlight_article3-title'>kuchen und gebäck</div>
    <div className='chef-hightlight_article3-text chef-hightlight_article3Right'>Was backe ich heute</div>
</div> 


        <div className='chef-hightlight_article3_container'>

<div className='chef-hightlight_article3'>
<div className='chef-hightlight_article3-img_container'><img src={Img2}/></div>

</div> 
</div>

   </>
 
  );


  }








export default ArtikelHighLight3Mob;
