import React,{useEffect, useState, Component} from 'react';
import { Outlet, Link } from 'react-router-dom';
import '../Styles/styles.scss'
import '../../styles/styles.scss'
import Logo from '../assets/Illustrierte-Online-Logo.svg'
import Menu from './Menu'

function Head() {


  useEffect(()=>{


},[])




  return (
    <>
  <div className="AdcheckHead">
     
   <div className='HeadLogo'>
    <img src={Logo}/>
   </div>
    
   
  
</div>
<Menu/>
<Outlet/>
</>
  );
}


  




export default Head;
