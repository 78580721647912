import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Logo from '../assets/logos/logo.svg'
import MenuIcon from '@mui/icons-material/Menu';
import Lupe from '../assets/logos/lupe.png'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './styles.scss'


function Head() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='chef-head'>
    <header className='chef-head_container'>
      <div className='chef-head-img_container'>
        <img src={Logo}/>

        <div className='chef-head-mobile_container'>
          <div className='chef-head-mobile-login'>Anmelden</div>
          <div className='chef-head-mobile_menu'><MenuIcon/><span>Menü</span></div>
        </div>
      </div>

      <div className='chef-head-search-nav_container'>
        <div className='chef-head-search_container'>
          <div className='chef-head-search'>
          <img id="chef-head-search-icon" src={Lupe}/>
          <div id="chef-head-search-text">z.B Pfannkuchen, Lasagne, Low Carb</div>
          </div>
         

        </div>
        <div className='chef-head-nav_container'>
          <ul className='chef-head-nav'>
            <li className='chef-head-nav-item'>PLUS</li>
            <li className='chef-head-nav-item'>Rezepte</li>
            <li className='chef-head-nav-item'>Magazin</li>
            <li className='chef-head-nav-item'>Videos</li>
            <li className='chef-head-nav-item'>Community</li>
          </ul>
        </div>
      </div>

    <div className='chef-head-actions_container'>
    <div className='chef-head-actions_icon'><CalendarMonthIcon style={{color:'#595852'}}/><span>Wochenplaner</span></div>
    <div className='chef-head-actions_icon'><FavoriteBorderIcon style={{color:'#595852'}}/><span>Kochbuch</span></div>
    <div className='chef-head-actions_login'>Anmelden</div>

    </div>

    </header>
   


   </div>
 
  );


  }




export default Head;
