import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import SternLogo from '../assets/logos/logo.svg'
import MenuIcon from '@mui/icons-material/Menu';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import './styles.scss'


function Head() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='stern-head'>
   
   <div className='stern-head_contaier'>
    <div className='stern-head_navigator-contaier'>

<div className='stern-head_navigator'>
<div className='stern-head_navigator_menu'>
  <MenuIcon/><span>Menü</span>
</div>
<div className='stern-head_navigator_icon'>
  <img src={SternLogo}/>
</div>
<div className='stern-head_navigator_account'>
  <span id="accout-text">Abo testen</span><span id="accout-text_mobile">Abo</span><PermIdentityOutlinedIcon style={{ fontSize: 30}}/>
</div>
</div>

    </div>
    <div className='stern-head_topbar-contaier'>
      <div className='stern-head_topbar'>
        <ul className='stern-head_topbar-list_container'>
        <li className='stern-head_topbar-list_item'>Ampel-Aus</li> 
        <li className='stern-head_topbar-list_item'>Inside Charité</li> 
        <li className='stern-head_topbar-list_item'>stern+</li> 
        <li className='stern-head_topbar-list_item'>GEO</li> 
        <li className='stern-head_topbar-list_item'>Capital</li> 
        <li className='stern-head_topbar-list_item'>stern Crime</li> 
        <li className='stern-head_topbar-list_item'>Gesellschaft</li> 
        <li className='stern-head_topbar-list_item'>Politik</li> 
        <li className='stern-head_topbar-list_item'>Panorama</li> 
        <li className='stern-head_topbar-list_item'>Kultur</li> 
        <li className='stern-head_topbar-list_item'>Lifestyle</li> 
        <li className='stern-head_topbar-list_item'>Digital</li> 
        <li className='stern-head_topbar-list_item'>Wirtschaft</li> 
        <li className='stern-head_topbar-list_item'>Sport</li> 
        <li className='stern-head_topbar-list_item'>Gesundheit</li> 
        <li className='stern-head_topbar-list_item'>Genuss</li> 
        <li className='stern-head_topbar-list_item'>Reise</li> 
        <li className='stern-head_topbar-list_item'>Familie</li> 
        <li className='stern-head_topbar-list_item'>Auto</li> 
        <li className='stern-head_topbar-list_item'>Stiftung stern</li>
        </ul>
        <div className='stern-head_topbar-next'>
        <button>
         <ArrowForwardIosOutlinedIcon/>
        </button>
        </div>
      </div>
    </div>
   </div>

   </div>
 
  );


  }




export default Head;
