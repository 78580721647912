import {React, useEffect, useState, Component, useRef} from "react";
import '../../styles.scss'
import PowerBtn from '../assets/power.svg'
import Data from '../../../../data/tv/data.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams  } from 'react-router-dom';
import Ads from '../../../../data/tv/ads_tv.json'
import BlackScreen from '../TvBlackScreen'


// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Tvspot(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const kanal = searchParams.get('k');


  const videoContainer=useRef(0)
  const videoAdContainer=useRef(0)
  const videoAd=useRef(0)
  const videoContent=useRef(0)
  const videoAdCount=useRef(0)
  const blackScreen=useRef(0)
  const videoLoader=useRef(null)
  const advideoLoader=useRef(null)

  const[adCount, setAdCount]=useState(0)

  const[Contentlaunch, setContentlaunch]=useState(false)

  useEffect(()=>{

    //handleRandomizeAd()

  },[])

  
  const launch=(e)=>{
    
    if(Contentlaunch==false){
    setTimeout(() => {
      blackScreen.current.style.visibility="hidden"
      e.target.play()
      e.target.addEventListener('timeupdate', handleContentVideoAdObserver)
      //startAd()
    }, 1500);
    setTimeout(() => {
      blackScreen.current.style.display="none"
      setContentlaunch(!Contentlaunch)
    }, 2500);
  }
  hideLoader()
  }



 async function handleRandomizeAd(){ 

    let ads=[]
    let adsTr=[]
    ads=Ads.ads.map((item,index)=>item.ad.url)
    adsTr=Ads.ads.map((item,index)=>item.ad.descr)

    let adTemp 
    let adTrTemp 
   
  
      for(let i=ads.length-1;i>=0; i--){
        let j = Math.floor(Math.random()*(i+1));
        adTemp=ads[i]
        ads[i]=ads[j]
        ads[j]=adTemp

        adTrTemp=adsTr[i]
        adsTr[i]=adsTr[j]
        adsTr[j]=adTrTemp

      }
  
       //eslint-disable-next-line
       sendResultFieldData({2:String("tv: " + adsTr)})
     
      return ads

  }


  const handleAdsSetup=()=>{

    let ads=[]

    ads=Ads.ads.map((item,index)=>item.ad.url)
     
    handleRandomizeAd(ads)

  }


  const adTime=(e)=>{
   
      setAdCount(Math.round(e.target.duration-e.target.currentTime))

  }

  const adEnd=()=>{
    adOff()
  }


  async function startAd(){

    const ads = await handleRandomizeAd()
    videoAdContainer.current.style.display="block"
    videoContent.current.style.display="none"
   
    await new Promise((resolve, reject)=>{

      const AdStart=()=>{
        videoAd.current.removeEventListener('ended', AdStart)
        resolve()
      }

      videoAd.current.src=Data.data[tv]._attributes.url_start //url start
     
      videoAd.current.addEventListener('ended', AdStart)

  
        videoAd.current.play() 
      
     
    })
      
 
    for(let i=0; i<ads.length; i++){
      try{
        videoAd.current.src=ads[i]

        await videoAd.current.play()
        
        await new Promise((resolve, reject)=>{

          const AdVideoEnd=()=>{
            videoAd.current.removeEventListener('ended',AdVideoEnd)
            resolve()
          }

          const onError = (error) => {
            videoAd.current.removeEventListener('ended', AdVideoEnd);
            reject(error);
        };

          videoAd.current.addEventListener('ended',AdVideoEnd)
        })


      }
      
      catch(error){
        console.log(error)
        return false
      }
    }

   await new Promise((resolve, reject)=>{

    const AdEnd=()=>{
      videoAd.current.removeEventListener('ended', AdEnd)
     videoAdContainer.current.style.display="none"
      videoContent.current.style.display="block"
      videoContent.current.play()
      resolve()
    }

    videoAd.current.src=Data.data[tv]._attributes.url_end
   
    videoAd.current.addEventListener('ended', AdEnd)


      videoAd.current.play() 
    
   
  })
    return true

  }


    


  const adOff=()=>{
   
     videoAdContainer.current.style.display="none"
    videoContent.current.play()
   
  }

  const showLoader=()=>{
   
    videoLoader.current.style.display="flex"
  
  }
    
  const hideLoader=()=>{
   videoLoader.current.style.display="none" 
  
   
  }
  const showAdLoader=()=>{
   
    advideoLoader.current.style.display="flex"
  
  }
    
  const hideAdLoader=()=>{
   advideoLoader.current.style.display="none" 
   
  }

  const handleContentVideoAdObserver=(e)=>{
   // let durationProdukt=Math.round(videoContent.current.duration)-Math.round(videoContent.current.currentTime)
    //let percentOfTime=(Math.round(videoContent.current.currentTime)*100)/Math.round(videoContent.current.duration) Math.floor(e.target.currentTime)
let time= Math.floor(videoContent.current.currentTime)
    if(time>=120){
      e.target.removeEventListener('timeupdate', handleContentVideoAdObserver)
      e.target.pause()
      startAd()
    }

    //console.log(Math.round(percentOfTime))
  }
  


    return(
      <>
        <div className="tv_etv_index-container">

        <div className="tv_etv_spot_content-container">
           <div className="tv_etv_spot_video-container" ref={videoContainer}>
            <div className="tv_etv_spot-blackscreen_container" ref={blackScreen}>
            <BlackScreen />
            </div>
            <Box className="tv_video_loader" ref={videoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoContent} playsInline src={Data.data[tv]._attributes.url} onWaiting={showLoader} onCanPlay={launch}></video>
           <div ref={videoAdContainer} className="tv_etv_spot_videoad-container" >
           <Box className="tv_video_loader" ref={advideoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoAd} playsInline  onTimeUpdate={adTime}  onWaiting={showAdLoader} onCanPlay={hideAdLoader}></video>
           </div>  
           </div>
        
        </div>


        </div>
    
        </>
    )
}

export default Tvspot;