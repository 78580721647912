import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import LogoFb from '../assets/logos/icon__fb.svg'
import LogoX from '../assets/logos/icon__twitter.svg'
import LogoInst from '../assets/logos/icon__instagram.svg'
import LogoMast from '../assets/logos/icon__mastodon.svg'
import LogoRss from '../assets/logos/icon__rss.svg'

import './styles.scss'


function Footer() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='ntv-footer'>

     <div className='ntv-footer-container'>

      <div className='ntv-footer-items'>
      <div className='ntv-footer-items-head'>ntv Nachrichtenfernsehen</div> 
      <div>Programm</div> 
      <div>Mitschnitte</div> 
      <div>Zuschauerredaktion</div> 
      <div>Pressestelle</div> 
      <div>Kontakt</div> 
      <div>Hinweisgeber</div> 
      <div>Jobs bei ntv.de</div> 
      <div>Digital Signage</div>
      </div>

      <div className='ntv-footer-items'>
      <div className='ntv-footer-items-head'>ntv.de Dienste</div> 
      <div>mobil & Apps</div> 
      <div>Newsletter</div> 
      <div>RSS-Feeds</div>
      </div>

      <div className='ntv-footer-items'>
      <div className='ntv-footer-items-head'>Social Networks</div> 
      <div>Facebook</div> 
      <div>X</div>
    
      <div className='ntv-footer-items-head'>Kooperationspartner</div> 
      <div>Gutscheine</div> 
      <div>Produktvergleiche</div> 
      <div>Autoleasing</div> 
      <div>Unternehmen</div> 
      <div>Immobilienbewertung</div> 
      <div>Broker-Vergleich</div> 
      <div>Website erstellen</div> 
      <div>Hosting</div>
      </div>

      <div className='ntv-footer-items ntvTextField'>
      <div className='ntv-footer-items-head'>Newsletter</div> 
      <div className='ntv-footer-items-text'>Ich möchte gerne Nachrichten und redaktionelle Artikel von der n-tv Nachrichtenfernsehen GmbH per E-Mail</div> 
      <div className='ntv-footer-items-text_field-container'>
        <div className='ntv-footer-items-text_field'></div>
        <div className='ntv-footer-items-text_field-btn'>Abonnieren</div>
      </div>
      </div>

    </div>


    <div className='ntv-footer2-container'>
      <div className='ntv-footer2-menu'>Impressum | Datenschutzerklärung | Privacy Center | Nutzungsbedingungen</div>

      <div className='ntv-footer2-icons'>
        <div className='ntv-footer2-icon'><img src={LogoFb}/></div>
        <div className='ntv-footer2-icon'><img src={LogoX}/></div>
        <div className='ntv-footer2-icon'><img src={LogoInst}/></div>
        <div className='ntv-footer2-icon'><img src={LogoMast}/></div>
        <div className='ntv-footer2-icon'><img src={LogoRss}/></div>
      </div>

      <div className='ntv-footer2-foot_text'>Alle Rechte vorbehalten</div>


    </div>

   </div>
 
  );


  }




export default Footer;
