import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'
import './styles.scss'


function SternHpa({adContent}) {


  const hpaAd=useRef(null)
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)


  useEffect(()=>{


    const callback=(entries, observerHpa)=>{
      entries.forEach(entry=>{
        const {target, isIntersecting}=entry
        if(isIntersecting){
          if(visibilityInterval.current){
            clearInterval(visibilityInterval.current)
          }

          visibilityInterval.current=setInterval(()=>{
            adVisibilityTime.current++
            //console.log(adVisibilityTime.current)
          //eslint-disable-next-line
        sendResultFieldData({2:String("visibility Stern-hpa " + Ads.hpa[adContent].ad +" : "+ adVisibilityTime.current)})
          },1000)
        }
    
        else{
          //console.log("STOP")
          clearInterval(visibilityInterval.current)
        //eslint-disable-next-line
        sendResultFieldData({2:String("visibility Stern-hpa " + Ads.hpa[adContent].ad +" : "+ adVisibilityTime.current)})
        }
    
    
      })
    }
    
    const options={
      threshold:0.5
    }
    
    const observerHpa=new IntersectionObserver(callback, options)
    
    observerHpa.observe(hpaAd.current)
    
  
},[])






  return (
   <div className='ad_klicks stern-ad-hpa' ref={hpaAd} data-click-tracking={Ads.hpa[adContent].ad}>

    <div className='stern-ad-hpa-container'>
    {Ads.hpa[adContent].type == 'mp4' ? <video src={Ads.hpa[adContent].url} loop playsInline muted autoPlay/> : <img src={Ads.hpa[adContent].url}/>}
    </div>
  
   </div>
 
  );


  }




export default SternHpa;
