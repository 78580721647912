import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams, useSearchParams} from "react-router-dom";
import Head from './Head/Head';
import NtvSideBar from './Ads/NtvSideBar';
import NtvHpa from './Ads/NtvHpa';
import NtvBb from './Ads/NtvBb';
import Panel from './Panel/Panel';
import Artikel from './Artikel/Artikel';
import ArtikelSide from './Artikel/ArtikelSide';
import ArtikelBox from './Artikel/ArtikelBox';
import Footer from './Footer/Footer';
import NtvMca from './Ads/NtvMca';
import NtvInterstitial from './Ads/NtvInterstitial';
import './styles.scss'


function Index() {

    const [searchParams] = useSearchParams();
    const sb = searchParams.get('sb');
    const hpa = searchParams.get('hpa');
    const bb = searchParams.get('bb');
    const mr = searchParams.get('mr');
    const mca = searchParams.get('mca');
    const inter = searchParams.get('inter');
 

    const mcaAdRef=useRef(null)
    const interAd=useRef(null)
    const bbAd=useRef(null)
    const sideAd=useRef(null)
    const hpaAd=useRef(null)
    const mrAd=useRef(null)

    let checkVar=0


  useEffect(()=>{

    if(mca!=null){
    ScrollEnd(()=>{

      mcaOn()


    },1000)
    
  }

  
  //console.log(mcaAd.current)
  //console.log(sideAd.current)
  //console.log(bbAd.current)
  //console.log(hpaAd.current)
  //console.log(interAd.current)
  //console.log(document.getElementById("ntv-ad-Medrec"))

  window.addEventListener('scroll', handleCalcScroll)

  






},[])


function mcaOff(){
  mcaAdRef.current.style.opacity=0
  setTimeout(() => {
    mcaAdRef.current.style.display="none"
  }, 500);
 

}

function mcaOn(){
  mcaAdRef.current.style.display="flex"
 
  setTimeout(() => {
    mcaAdRef.current.style.opacity=1
  }, 0);
 

}


function ScrollEnd(callback, timeout){
  let timer=null
  
  window.addEventListener('scroll', function(){
    mcaOff()
    if(timer){
      clearTimeout(timer)
    }

    timer=setTimeout(() => {

      callback()
      timer=null
      
    }, timeout);

  })
}


const handleCalcScroll=()=>{
  
  let fullSize=(document.documentElement.scrollHeight-window.scrollY)-document.documentElement.clientHeight+window.scrollY
  let percentResult=Math.round((window.scrollY*100)/fullSize)

 
  if(percentResult>=checkVar){
    checkVar=percentResult
    //console.log("check: " + checkVar + " percent: " + percentResult)
    //eslint-disable-next-line
    sendResultFieldData({7:String("Scroll " + percentResult)})
  }
 
    //console.log("percent<check " + " check: " + checkVar + " percent: " + percentResult)


}




  return (
    
   <div className='ntv-container'>

{inter !=null ? <div className='ntv-ad-inter-main_container' id="ntv-ad-inter" ><NtvInterstitial adContent={inter}/></div> : <div></div> }

    
 
 <div className='ntv-container_main'>

 {hpa!=null ? <div className='ntv-ad-sidebar-main_container'><NtvHpa adContent={hpa}/></div>: <div className='ntv-ad-sidebar-main_container'> </div>}
    
    <div className='ntv-container_article'>
    <div className='ntv-main-head_container'>
    <Head/>

    </div>

 

    <div className='ntv-main-billboard_container'>
    {bb !=null ? <div className='ntv-ad-billboard-main_container'><NtvBb adContent={bb}/></div> : <div className='ntv-ad-nobillboard-main_container'></div> }
    </div>

    <div>
    <Panel/>
    </div>

    <div className='ntv-article-container'>
      <div className='ntv-article-main'>
    <Artikel teaser={true} adContent={mr}/>
      </div>
      <div className='ntv-article-side'>
      <div className='ntv-article-side-head'>Videos</div>
      <ArtikelSide/>
      </div>
     
    </div>

    <div className='ntv-article-head'>Videos Meistgesehen</div>
    <div className='ntv-article-box'>
   
    <ArtikelBox/>
    </div>

    <div className='ntv-article-container'>
    <div className='ntv-article-main'>

    <Artikel teaser={false}/>
      </div>

      </div>


      <div className='ntv-article-footer'>
  <Footer/>
    </div>

    

    </div>

{sb !=null ? <div className='ntv-ad-sidebar-main_container'> <NtvSideBar adContent={sb}/> </div>: <div className='ntv-ad-sidebar-main_container'> </div>}

{/* 
    {sb !=null ? <div className='ntv-ad-sidebar-main_container'><NtvSideBar adContent={sb}/></div> : <div className='ntv-ad-sidebar-main_container'> </div>}
    {hpa !=null ? <div className='ntv-ad-sidebar-main_container'><NtvSideBar adContent={hpa}/></div> : <div className='ntv-ad-sidebar-main_container'> </div>}
*/}



    
    </div>



    {mca !=null ? <div className='ntv-ad-mca-main_container' ref={mcaAdRef} ><NtvMca adContent={mca}/></div> : <div></div> }
     

    
   </div>
   
 
  );

/* 
  function SetAd(){
    switch(ad){
        case "1":
      return<ArticleDefine/>

      case "2":
        return<ArticleDefine/>

      case "3":
        return<ArticleDefine/>

      case "4":
        return<ArticleDefine/>
     
    }
       
}
     */
  
   
  }




export default Index;
