import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import SternLogo from '../assets/logos/logo.svg'

import './styles.scss'


function Footer() {



  



  useEffect(()=>{


    
  
},[])






  return (

   <div className='stern-footer'>

<div className='stern-footer_container'>
<div className='stern-footer-logo_container'>
  <img src={SternLogo}/>
</div>

<ul className='stern-footer-nav_container'>
  <li>Impressum</li>
  <li>Redaktionelle Richtlinien</li>
  <li>AGB</li>
  <li>Datenschutzhinweise</li>
  <li>Netiquette</li>
  <li>Verträge hier kündigen</li>
  <li>Werbung</li>
  <li>Datenschutz-Einstellungen</li>
  <li>Kontakt</li>
  <li>Browserbenachrichtigungen</li>
</ul>

<div className='stern-footer-social-icons_container'>
  
</div>

</div>
   

   </div>
 
  );


  }




export default Footer;
