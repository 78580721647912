import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Logo from '../assets/logos/logo.svg'
import Menu from '../assets/logos/menu.svg'

import './styles.scss'


function Head() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='gala-head'>
    <header className='gala-head_container'>
      <div className='gala-head-logo-container'>
        <img src={Logo}/>
      </div>

      <div className='gala-head-nav-links_container'>
        <span className='gala-head-nav-links_abo'>ABO</span>
        <ul className='gala-head-nav-links'>
          <li className='gala-head-nav-links-item'>stars</li>
          <li className='gala-head-nav-links-item'>beauty&#38;fashion</li>
          <li className='gala-head-nav-links-item'>lifestyle</li>
          <li className='gala-head-nav-links-item'>royals</li>
        </ul>
      </div>

      <div className='gala-head-menu-container'>
      <img src={Menu}/>
      </div>

    </header>


   </div>
 
  );


  }




export default Head;
