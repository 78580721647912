import React,{useEffect, useState, Component, useRef} from 'react';
import './style.scss'
import MainImg from '../assets/ads_define/neurexan/main_img.webp'
import imgExpand1 from '../assets/ads_define/neurexan/imgexpand.webp'
import imgExpand2 from '../assets/ads_define/neurexan/imgexpand2.webp'
import imgExpand3 from '../assets/ads_define/neurexan/imgexpand3.webp'
import imgExpand4 from '../assets/ads_define/neurexan/imgexpand4.webp'
import vidPoster from '../assets/ads_define/neurexan/videoprev.jpg'
import spotiLogo from '../assets/ads_define/neurexan/spotifyLogo.png'
import defileLogo from '../assets/ads_define/define_logo.svg'


function Neurexan() {


  const adContainer=useRef(null)
  const adContainerExpand=useRef(null)
  const adNeurexanContainerFooter=useRef(null)
  const adContainerTitle=useRef(null)
  const adContainerText=useRef(null)
  const ctaBtnExpand=useRef(null)
  const ctaBtnCloseExpand=useRef(null)



  useEffect(()=>{


    
  
},[])


const expandAd=()=>{

adContainer.current.classList.add("neurexan_ad-img_container_expand")
adContainerTitle.current.classList.add("neurexan_ad_container-title_expand")
adContainerText.current.classList.add("neurexan_ad_container-text_expand")
ctaBtnExpand.current.style.display="none" // flex
adContainerExpand.current.style.display="block" 
ctaBtnCloseExpand.current.style.display="block" 



}
const closeExpandAd=()=>{

adContainer.current.classList.remove("neurexan_ad-img_container_expand")
adContainerTitle.current.classList.remove("neurexan_ad_container-title_expand")
adContainerText.current.classList.remove("neurexan_ad_container-text_expand")
ctaBtnExpand.current.style.display="flex" 
adContainerExpand.current.style.display="none" 
ctaBtnCloseExpand.current.style.display="none" 
adNeurexanContainerFooter.current.scrollIntoView({block:"end"});



}



  return (
   <div className='neurexan_ad'>
    <div className='neurexan_ad_container'>
        <div className='neurexan_ad_top'>Anzeige</div>

       
        <div className='neurexan_ad-img_container' ref={adContainer}>
       <div><img src={MainImg}/></div>
       

        <div className='neurexan_ad_container-text_cta'>
        <div className='neurexan_ad_container-title' ref={adContainerTitle}>
            <div>Ein- & Durchschlaf­probleme? 4 Tipps für guten Schlaf!</div>
        </div>

        <div className='neurexan_ad_container-text' ref={adContainerText}>
            <div>Wer nachts gut schläft, kann erholter in den neuen Tag starten. Doch was tun, wenn der so wichtige Schlaf ausbleibt? </div>
        </div>

        <div className='neurexan_ad_cta-container'  ref={ctaBtnExpand}>
        <div className='neurexan_ad_cta_cta' onClick={expandAd}>Jetzt mehr erfahren</div>
        </div>
        
        </div> {/* neurexan_ad_container-text_cta end*/}
        </div>

        
        
      
        <div className='neurexan_ad_container_expand' ref={adContainerExpand}>

          <div className='neurexan_ad_expand-block'>
            <div className='neurexan_ad_expand-block_img-container'>
              <img src={imgExpand1}/>
            </div>
            <div className='neurexan_ad_expand-block-text_container'>
            <div className='neurexan_ad_expand-block_title'>
            Neurexan® - für erholsamen Schlaf in der Nacht!
            </div>
            <div className='neurexan_ad_expand-block_list'>
              <ul>
                <li>sorgt für schnelleres Einschlafen</li>
                <li>verlängert die nächtliche Schlafdauer</li>
                <li>wirkt schnell und ohne abhängig zu machen</li>
              </ul>
            </div>
            </div>

           
              <div className='neurexan_ad_expand-block-cta'>Jetzt kaufen</div>
           
          </div>


          <div className='neurexan_ad_expand-video_poster'>
            <div className='neurexan_ad_expand-video_poster-playIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="44px" height="44px" className="jw-svg-icon jw-svg-icon-play" viewBox="0 0 240 240" focusable="false" fill='white'><path d="M62.8,199.5c-1,0.8-2.4,0.6-3.3-0.4c-0.4-0.5-0.6-1.1-0.5-1.8V42.6c-0.2-1.3,0.7-2.4,1.9-2.6c0.7-0.1,1.3,0.1,1.9,0.4l154.7,77.7c2.1,1.1,2.1,2.8,0,3.8L62.8,199.5z"></path></svg>
            </div>
            <div className='neurexan_ad_expand-video_poster-img_container'><img src={vidPoster}/></div>
          
        </div>



        <div className='neurexan_ad_expand-block'>
            <div className='neurexan_ad_expand-block_img-container'>
              <img src={imgExpand2}/>
            </div>
            <div className='neurexan_ad_expand-block-text_container'>
            <div className='neurexan_ad_expand-block_title'>
            Stress? Schlafprobleme? Widerstandskraft stärken!
            </div>
            <div className='neurexan_ad_expand-block_text'>
            <div> Stress – in vielen Situationen positiv, über eine längere Zeit aber eine Belastung für den Schlaf. Was tun um die Stressresistenz zu verbessern?</div>
            </div>
            </div>

           
              <div className='neurexan_ad_expand-block-cta'>Mehr erfahren</div>
           
          </div>





          <div className='neurexan_ad_expand-block'>
            <div className='neurexan_ad_expand-block_img-container'>
              <img src={imgExpand3}/>
            </div>
            <div className='neurexan_ad_expand-block-text_container'>
            <div className='neurexan_ad_expand-block_title'>
            Neurexan® - für erholsamen Schlaf in der Nacht!
            </div>
            <div className='neurexan_ad_expand-block_list'>
              <ul>
                <li>reguliert das Stresshormon Cortisol <sup>1</sup></li>
                <li>hilft Belastungssituationen am Tag besser zu meistern</li>
                <li>für erholsamen Schlaf</li>
              </ul>
            </div>
            </div>

           
              <div className='neurexan_ad_expand-block-cta'>Jetzt kaufen</div>
           
          </div>


          <div className='neurexan_ad_expand-block'>
            <div className='neurexan_ad_expand-block_img-container'>
              <img src={imgExpand4}/>
            </div>
            <div className='neurexan_ad_expand-block-text_container'>
            <div className='neurexan_ad_expand-block_title'>
            Entspannen und Einschlafen mit spezieller Musik?
            </div>
            <div className='neurexan_ad_expand-block_text'>
            <div> Gönnen Sie sich einen Moment der Erholung mit 6 speziell für Ihre Auszeit komponierten Playlists mit Entspannungsmusik.</div>
            </div>
            </div>

           
              <div className='neurexan_ad_expand-block-cta'>Neurexan | Spotify <img src={spotiLogo}/></div>
           
          </div>
          


       

        </div> {/*Expand end*/}


     
      
       

     
      <div className='neurexan_ad-footer_container' ref={adNeurexanContainerFooter}>
      <div className='neurexan_ad-footer'>AI-driven <img src={defileLogo}/> <span>- Pflichtangaben</span></div>
      <div className='neurexan_ad-footer-closeBtn_expand' ref={ctaBtnCloseExpand} onClick={closeExpandAd}>Schließen</div>
      </div>
    </div>
   </div>
 
  );


  
   
  }




export default Neurexan;
