import React,{useEffect, useState, Component} from 'react';
import Profileimg from '../../Images/img/profile.png'
import '../BodyStyle/styles.scss'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import AddIcon from '@mui/icons-material/Add';

function Profile() {


  useEffect(()=>{

  
  
},[])




  return (
<div className="LinkedProfileBody">

    <div className="LinkedProfile">
      <div className='LinkedProfileDiv'>
        <img src={Profileimg}/>
      </div>
    <div className='ProfileText'>Willkommen, Benutzer</div>

    <hr className='ProfileLine'/>

    <div className='ProfileText2'>Kontakte, <span>Netzwerke erweitern</span>
    <div style={{position:'absolute', right:10, top:0}}><PersonAddIcon style={{fontSize:20}}/></div>
    </div>

    <hr className='ProfileLine'/>

    <div className='ProfileText2'>Zugang zu exklusiven Tools und Analysen<span>Premium gratis testen</span></div>

    <hr className='ProfileLine'/>

    <div className='ProfileElemente'><TurnedInIcon style={{fontSize:20}}/>Ihre Elemente</div>

     </div>


     <div className="LinkedProfile2">
     <div className="LinkedProfile2Text">Gruppen</div>
     <div className="LinkedProfile2Text">Events</div>
     <div className="LinkedProfile2Text">Ihre Hashtags</div>
      <div className='LinkedProfile2Add'><AddIcon/></div>
     <hr className='ProfileLine Special'/>

     <div id="rk800">Mehr Entdecken</div>

     </div>




</div>

  );
}


  


export default Profile;
