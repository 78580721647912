import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NtvLogo from '../assets/logos/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import './styles.scss'


function Head() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='ntv-head'>
    <div className='ntv-head_container'>
    <div className='ntv-mobile-hamburger_container'>
        <MenuIcon sx={{height:'100%', width:'28px', color:'white'}}/>
        </div>
      <div className='ntv-head-logo-container'>
       
        <img src={NtvLogo}/>
      </div>

      <div className='ntv-head-nav-container'>
      <div className='ntv-head-nav'>
        <ul className='ntv-head-nav-list'>
       
          <li><div className='ntv-head-nav-list-item'>ressorts</div></li>
          <li><div className='ntv-head-nav-list-item'>sport</div></li>
          <li><div className='ntv-head-nav-list-item'>börse</div></li>
          <li><div className='ntv-head-nav-list-item'>wetter</div></li>
          <li><div className='ntv-head-nav-list-item'>video</div></li>
          <li><div className='ntv-head-nav-list-item'>audio</div></li>
          <li><div className='ntv-head-nav-list-item'>tv programm</div></li>
          <li style={{marginLeft:'10px'}}><div className='ntv-head-nav-list-item'><div id="ntv-live"></div>live-tv</div></li>
        </ul>
      </div>
      </div>

    <div className='ntv-head-nav-action-container'>

    <ul className='ntv-head-nav-list'>

    <div className='ntv-head-nav-action-mobile_container'>
   <div>video</div>
    </div>

    <div className='ntv-head-nav-action-mobile_container'>
    <li style={{marginLeft:'10px'}}><div className='ntv-head-nav-list-item'><div id="ntv-live"></div>live-tv</div></li>
    </div>

    <div className='ntv-head-nav-action-icon_container'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M1 11.8C1 5.8 5.8 1 11.8 1C17.7 1 22.6 5.8 22.6 11.8C22.6 17.8 17.8 22.6 11.8 22.6C5.8 22.6 1 17.8 1 11.8ZM11.8 15.2C7.4 15.2 6.9 18.3 6.8 19.5C8.2 20.5 10 21 11.8 21C13.6 21 15.4 20.4 16.8 19.5C16.7 18.2 16.2 15.2 11.8 15.2ZM11.7 13.7C15.8 13.7 17.6 16 18.1 18.4C19.9 16.8 21 14.4 21 11.8C21 6.6 16.7 2.5 11.6 2.5C6.5 2.5 2.4 6.6 2.4 11.7C2.4 14.3 3.5 16.7 5.3 18.4C5.8 16 7.6 13.7 11.7 13.7ZM8 8.8C8 6.7 9.7 5 11.8 5C13.8 5 15.6 6.7 15.6 8.8C15.6 10.9 13.9 12.6 11.8 12.6C9.7 12.6 8 10.9 8 8.8ZM9.6 8.8C9.6 10 10.6 11 11.8 11C13 11 14 10 14 8.8C14 7.6 13 6.6 11.8 6.6C10.6 6.6 9.6 7.6 9.6 8.8Z" fill="#FFFFFF"/>
</svg>
    </div>
    <div className='ntv-head-nav-action-icon_container'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    
   
        <path d="M9.98224852,0 C13.7867456,0 15.0484355,2.56045421 15.4349076,5.26570652 L15.4888994,5.69370274 L15.5298034,6.12199246 L15.5590782,6.54846253 L15.5781828,6.97099984 L15.5885758,7.38749126 L15.591716,7.79582367 C15.591716,9.69064192 16.1696526,11.4917218 16.7834355,12.8690854 L17.0006704,13.3370826 C17.0729897,13.4868589 17.1449225,13.6302333 17.215586,13.7666684 L17.4228859,14.154619 L17.6172741,14.4977104 L17.8730611,14.9211843 L18.1597839,15.3570066 L18.2189349,15.438788 L19,16.4705882 L1,16.4705882 L1.77394867,15.3997148 L1.95810065,15.1225176 L2.10729609,14.8835467 L2.28503918,14.5846045 C2.31668983,14.5300085 2.34926584,14.4730827 2.38263516,14.4139115 L2.5913141,14.0326168 L2.81269319,13.6014901 C3.52714442,12.1616359 4.33727811,10.0332307 4.33727811,7.79582367 C4.33727811,3.17545404 5.83377293,1.29345003 7.34115281,0.526857878 L7.59939023,0.406065739 C7.64235517,0.387653855 7.68525969,0.370076804 7.72806916,0.353296735 L7.98350797,0.261877263 C8.06808919,0.234390146 8.15201296,0.209788127 8.23500208,0.187768381 L8.48088827,0.129153136 L8.71950337,0.0842145783 L8.94918418,0.0511357563 L9.37509019,0.0132895147 L9.74530077,0.00107880212 L9.98224852,0 Z M9.98697149,1.22003282 L9.75002374,1.22111162 C7.02703473,1.25529534 5.46227811,2.64020005 5.46227811,7.79582367 C5.46227811,9.56261403 5.02904204,11.354054 4.29227236,13.1110259 C3.98737817,13.838106 3.65436426,14.5041395 3.32618255,15.0826359 L3.2275,15.2505447 L16.665625,15.2505447 L16.5429876,15.0429213 C15.5716371,13.352733 14.5588712,10.6366144 14.4726408,8.13544355 L14.466716,7.79582367 C14.466716,3.50465647 13.4164338,1.33016436 10.2358516,1.22411176 L9.98697149,1.22003282 Z M13.375,17.6618453 L13.375,18.839372 C11.9732012,19.613124 10.8850266,20 10.1104763,20 C9.33592605,20 8.1741006,19.613124 6.625,18.839372 L6.625,17.6618453 L10.0996061,18.839372 L13.375,17.6618453 Z" id="Combined-Shape" fill="#FFFFFF"/>
    
</svg>
    </div>
    <div className='ntv-head-nav-action-icon_container'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 24" version="1.1">
  
    <path d="M21.5615487,23.2309746 L17.3639519,17.1108144 C19.7351135,15.3805073 21.3052069,12.5927904 21.3052069,9.45260347 C21.3052069,4.22963952 17.0755674,0 11.8526035,0 C6.62963952,0 2.4,4.22963952 2.4,9.45260347 C2.4,14.6755674 6.62963952,18.9052069 11.8526035,18.9052069 C13.4226969,18.9052069 14.8646195,18.5206943 16.1783712,17.8477971 L20.4080107,24 L21.5615487,23.2309746 Z M3.80987984,9.45260347 C3.80987984,5.03070761 7.43070761,1.40987984 11.8526035,1.40987984 C16.2744993,1.40987984 19.8953271,5.03070761 19.8953271,9.45260347 C19.8953271,13.8744993 16.2744993,17.4953271 11.8526035,17.4953271 C7.39866489,17.4953271 3.80987984,13.9065421 3.80987984,9.45260347 Z" id="path-1" fill="#FFFFFF"/>
   
</svg>
    </div>
    
    </ul>
    </div>

    </div>
   </div>
 
  );


  }




export default Head;
