import React,{useEffect, useState, Component} from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';

function Footer() {



  useEffect(()=>{


  
},[])




  return (
    <div className='adcheck-online-footer'>

    <div className='adcheck-footertext-online-container'>
    <div className='footer-online-channels'>
    <div className='online-channelsItem'><InstagramIcon sx={{fontSize:30, color:'white'}}/></div>
    <div className='online-channelsItem'><FacebookIcon sx={{fontSize:30, color:'white'}}/></div>
    <div className='online-channelsItem'><PinterestIcon sx={{fontSize:30, color:'white'}}/></div>
    </div>
    <div className='footer-online-linksItems'>
      <div className='online-linkItem'>Impressum</div>
      <div className='online-linkItem'>Presse</div>
      <div className='online-linkItem'>Datenschutz</div>
      <div className='online-linkItem'>Kontakt</div>
      <div className='gos-linkItem-container'>
      <div className='gos-linkItem'>Nutzungsbedingungen </div>
      <div className='gos-linkItem'>Jobs</div>
      </div>
    </div>

    </div>

  </div>
  );



}


  




export default Footer;
