import {React, useEffect, useState, Component, useRef} from "react";
import '../../../styles.scss'
import {useParams, useSearchParams  } from 'react-router-dom';



function RtlSide(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const tvad = searchParams.get('tvad');


  useEffect(()=>{

  },[])

 

  


    return(
    
        <div className="rtl-sidebumper_container">

<div className="tv_etv_spot_side-bumper-AdHeadText_container">
            <div className="tv_etv_spot_side-bumper-AdHeadText">Anzeige</div>
            <div className="tv_etv_spot_side-bumper-AdHeadText"><div className="tv_etv_spot_side-bumper-blue"></div><span>Datenschutz</span></div>
            </div>

          
            <div className="tv_etv_spot_side-bumper_text">Werbung</div>
          

        </div>
    
       
    )



}

export default RtlSide;