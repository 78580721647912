import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'

import './styles.scss'


function NtvMca({adContent}) {


  const mcaAd=useRef(null)
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)


  useEffect(()=>{


    
   const callback=(entries, observerMca)=>{
    entries.forEach(entry=>{
      const {target, isIntersecting}=entry
      if(isIntersecting){
        if(visibilityInterval.current){
          clearInterval(visibilityInterval.current)
        }

        visibilityInterval.current=setInterval(()=>{
          adVisibilityTime.current++
          //console.log(adVisibilityTime.current)
           //eslint-disable-next-line
        sendResultFieldData({4:String("visibility NTV-mca " + Ads.mca[adContent].ad +" : "+ adVisibilityTime.current)})
        },1000)
      }
  
      else{
        //console.log("STOP")
        clearInterval(visibilityInterval.current)
         //eslint-disable-next-line
         sendResultFieldData({4:String("visibility NTV-mca " + Ads.mca[adContent].ad +" : "+ adVisibilityTime.current)})
      }
  
  
    })
  }
  
  const options={
    threshold:0
  }
  
  const observerMca=new IntersectionObserver(callback, options)
  
  observerMca.observe(mcaAd.current)
    
  
},[])






  return (
   <div className='ad_klicks ntv-ad-mca' ref={mcaAd} data-click-tracking={Ads.mca[adContent].ad}>

    <div className='ntv-ad-mca-container'>
      
    {Ads.mca[adContent].type == 'mp4' ? <video src={Ads.mca[adContent].url} loop playsInline muted autoPlay/> : <img src={Ads.mca[adContent].url}/>}
    
    </div>
  
   </div>
 
  );


  }




export default NtvMca;
