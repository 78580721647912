import React,{useEffect, useState, Component} from 'react';

import '../Styles/styles.scss'
import '../../styles/styles.scss'



function GosMenu() {


  useEffect(()=>{


},[])




  return (
    <>
  <div className="Adcheck-genAI-online-menu">

    <div className='genAI-online-menuItems-container'>
    <div className='genAI-online-menuItem'>Politik</div>
    <div className='genAI-online-menuItem'>Sport</div>
    <div className='genAI-online-menuItem'>Finanzen</div>
    <div className='genAI-online-menuItem'>Wissen</div>
    <div className='genAI-online-menuItem'>Reisen</div>
    <div className='genAI-online-menuItem'>Gesundheit</div>
 
    </div>
</div>

</>
  );
}


  




export default GosMenu;
