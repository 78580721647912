import React,{useEffect, useState, Component, useRef} from 'react';
import placeholder from '../assets/bb_placeholder.png'
import {useParams, useSearchParams, useLocation} from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import DataText from '../../../data/adcheck_genai/texts.json'

import Articl from '../../../data/adcheck_genai/article.json'
import '../Styles/styles.scss'


//mini article

import MiniArtikel1 from '../assets/online_artikel/1.jpg'
import MiniArtikel2 from '../assets/online_artikel/2.jpg'
import MiniArtikel3 from '../assets/online_artikel/3.jpg'


//ads

import bb from '../assets/ads/AIRS_970x250.png'
import medrec from '../assets/ads/bittersweet_300x250.gif'
import skyScr from '../assets/ads/ChocBar_160x600.png'
import { Widgets } from '@mui/icons-material';

//Define Ads
import Neurexan from '../Ads/Neurexan';
import NrwBank from '../Ads/NrwBank';


function ArticleDefine() {

const {article}= useParams()
const [searchParams] = useSearchParams();
const ad = searchParams.get('ad');
  

  function getDate(){
    const date=new Date()
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth()+1).padStart(2, '0')
    const year = date.getFullYear()
    
    return `${day}.${month}.${year}`
   }


  useEffect(()=>{


    
  
},[])




  return (
    <>
  <div className="adcheck-define">

    <div className='adcheck-define_container'>

      <div className='adcheck-define_container-bbContainer'>
      <img src={bb}/>
      </div>

    <div className='adcheck-define-skyscr-container'>

      <div className='define-mainarticle-miniacticle-container'>
      <div className='define-mainArticle-container defineArticle'>

      <div className='define-mainArticle-text-container-header'>{DataText.texts[article].head}</div>
      <div className='define-mainArticle-text-container-label defineTextType'>{"Veröffentlicht am "+getDate()+ DataText.option[0].type}</div>

      <div className='define-mainArticle-img-container-article'>
      <img src={DataText.texts[article].img}/>
    
      </div>
      <div className='define-mainArticle-articleText-container'>
      
     <ArticleText/>
      </div>

      </div>


      
      <div className='define-ad_container'>
      <SetAd/>
      </div>

      <div className='define-remark'>Auch Interessant</div>

      <div className='define-skyScrapper-container-mobile'>
      <img src={skyScr}/>
      </div>

      <div className='define-remark-mobile'>Auch Interessant</div>

      <div className='define-miniArticle-container-mobile'>
        <div className='define-miniArticle-mobile'><div style={{display:'flex',justifyContent:'center'}}><div className='define-miniarticle-line-mobile'></div></div><div className='online-miniarticle-title-mobile'>Finanzen</div><div className='online-miniarticleImg-container-mobile'><img src={MiniArtikel1}/></div><div className='online-miniarticle-text-mobile'>Arbeiter im Hamburger Hafen treten in Warnstreik</div></div>
        <div className='define-miniArticle-mobile'><div style={{display:'flex',justifyContent:'center'}}><div className='define-miniarticle-line-mobile'></div></div><div className='online-miniarticle-title-mobile'>Wissen</div><div className='online-miniarticleImg-container-mobile'><img src={MiniArtikel2}/></div><div className='online-miniarticle-text-mobile'>Fünf Pflanzen halten Zecken aus Ihrem Garten fern</div></div>
        <div className='define-miniArticle-mobile'><div style={{display:'flex',justifyContent:'center'}}><div className='define-miniarticle-line-mobile'></div></div><div className='online-miniarticle-title-mobile'>Nachrichten</div><div className='online-miniarticleImg-container-mobile'><img src={MiniArtikel3}/></div><div className='online-miniarticle-text-mobile'>Dyson entlässt 1000 Mitarbeiter in Großbritannien</div></div>
       
      </div>


     
      




      <div className='main-container-define-articleContainer'>
 
      <Article/>

      </div>
      </div>


      <div className='define-miniArticle-container'>
        <div className='define-miniArticle'><div style={{display:'flex',justifyContent:'center'}}><div className='online-miniarticle-line'></div></div><div className='online-miniarticle-title'>Finanzen</div><div className='online-miniarticleImg-container'><img src={MiniArtikel1}/></div><div className='online-miniarticle-text'>Arbeiter im Hamburger Hafen treten in Warnstreik</div></div>
        <div className='define-miniArticle'><div style={{display:'flex',justifyContent:'center'}}><div className='online-miniarticle-line'></div></div><div className='online-miniarticle-title'>Wissen</div><div className='online-miniarticleImg-container'><img src={MiniArtikel2}/></div><div className='online-miniarticle-text'>Fünf Pflanzen halten Zecken aus Ihrem Garten fern</div></div>
        <div className='define-miniArticle'><div style={{display:'flex',justifyContent:'center'}}><div className='online-miniarticle-line'></div></div><div className='online-miniarticle-title'>Nachrichten</div><div className='online-miniarticleImg-container'><img src={MiniArtikel3}/></div><div className='online-miniarticle-text'>Dyson entlässt 1000 Mitarbeiter in Großbritannien</div></div>
       
      </div>


    

      </div>

    </div>

    <div className='define-skyScrapper-container'>
        <img src={skyScr}/>
      </div>
   
  
</div>
<Footer/>
</>
  );

  
  function SetAd(){
    switch(ad){
      case "1":
    return<Neurexan/>

    case "2":
      return<NrwBank/>

    case "3":
      return<Neurexan/>

    case "4":
      return<Neurexan/>
   
  }
  }

  
function ArticleText(){

return DataText.texts.map((item,index)=>{
if(index==article){
  return(
    <>
    <div className='define-mainArticle-text-container-text'>
    {item.text.split('\n').map((line,index)=>{
        
     return(
       <p key={index}>{line}</p>
      )
       })}
     </div>
     </>
       )
      }
}) 
  
}
  
function Article(){

  return Articl.article.map((item,index)=>{
  if(index==0){
    return(
      <>
      <div className='adcheck-define_container-subarticle' key={index}>
      <div className='adcheck-main-define-subarticle-img'>
        <img src={medrec}/>
      </div>
     
      </div>
      <div className='adcheck-define_container-subarticle' key={index}>
        <div className='adcheck-main-define-subarticle-img'>
          <img src={item.img}/>
        </div>
        <div className='adcheck-main-define-subarticle-text'>
        {item.text}
        </div>
        </div>
      </>
         )
        }
        else{
          return(
            <>
            <div className='adcheck-define_container-subarticle' key={index}>
              <div className='adcheck-main-define-subarticle-img'>
                <img src={item.img}/>
              </div>
              <div className='adcheck-main-define-subarticle-text'>
              {item.text}
              </div>
              </div>
            </>
               )
        }
  }) 
    
  }

}


  




export default ArticleDefine;
