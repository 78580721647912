import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";

import Ads from '../../../../data/inpage/ntv/ads.json'
import './styles.scss'


function NtvSideBar({adContent}) {
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)

  const sideAd=useRef(null)



  useEffect(()=>{

    

    const callback=(entries, observerSide)=>{
      entries.forEach(entry=>{
        const {target, isIntersecting}=entry
        if(isIntersecting){
        
          if(visibilityInterval.current){
            clearInterval(visibilityInterval.current)
          }

          visibilityInterval.current=setInterval(()=>{
            adVisibilityTime.current++
            //console.log(adVisibilityTime.current)
            //eslint-disable-next-line
        sendResultFieldData({6:String("visibility NTV-sidebar " + Ads.sb[adContent].ad +" : "+ adVisibilityTime.current)})
          },1000)

        }
    
        else{
          //console.log("STOP")
          clearInterval(visibilityInterval.current)
          //eslint-disable-next-line
        sendResultFieldData({6:String("visibility NTV-sidebar " + Ads.sb[adContent].ad +" : "+ adVisibilityTime.current)})
        }
    
    
      })
    }
    
    const options={
      threshold:0
    }
    
    const observerSide=new IntersectionObserver(callback, options)
    
    observerSide.observe(sideAd.current)
    
  
},[])






  return (
   <div className='ad_klicks ntv-ad-sidebar' ref={sideAd} data-click-tracking={Ads.sb[adContent].ad}>

    <div className='ntv-ad-sidebar-container'>
    {Ads.sb[adContent].type == 'mp4' ? <video src={Ads.sb[adContent].url} className={Ads.sb[adContent].class} loop playsInline muted autoPlay/> : <img src={Ads.sb[adContent].url}/>}
     
    </div>
  
   </div>
 
  );


  }




export default NtvSideBar;
