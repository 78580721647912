import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'

import Data from '../../../../data/inpage/ntv/data-video.json'
import './styles.scss'


function ArtikelSide() {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
        <SetMain/>

   </>
 
  );


  }


  function SetMain(){

    return Data.rss.channel.item.map((item,index)=>{
      const [beforeColon, afterColon] = item.title.split(':');
        
        return(
<div className='ntv-article-side-videos_container' key={index}>
            <div className='ntv-article-side-videos-img-container'>
            <img src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
            <div className='ntv-article-side-videos-video_icon'>
                <div className='ntv-article-side-videos-video_icon-play'></div>
            </div>
            </div>
            
            <div className='ntv-article-text-container-side'>
                <div className='ntv-article-side-theme'> {item.category}</div>
                <div className='ntv-article-side-desk-head'>
                {beforeColon}
                </div>

                <div className='ntv-article-side-desk-text'> {afterColon}</div>

                 
                </div>

        </div>
        )
    })
   

   }





export default ArtikelSide;
