import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import Dummy3 from '../assets/img/dummy3.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'


function ArtikelTeaserBlock({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
     <swiper-slide style={{display:'flex', justifyContent: 'center',  position:'relative'}}>
    <div className='chef-blockItem-article_slide'>
    
    <div className='chef-blockItem-img_container'>
      <img src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
      <div className='chef-blockItem-name_container'>{title}</div>
    </div>
    
  
   

      </div>
      </swiper-slide>
    

   </>
 
  );


  }






export default ArtikelTeaserBlock;
