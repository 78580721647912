import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams, useSearchParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import DayData from '../../../../data/inpage/ntv/data_day.json'
import Data from '../../../../data/inpage/ntv/data.json'
import Data2 from '../../../../data/inpage/ntv/data2.json'
import DataVideo from '../../../../data/inpage/ntv/data-video.json'
import Ads from '../../../../data/inpage/ntv/ads.json'
import NtvMr from '../Ads/NtvMr';
import './styles.scss'
import NtvBb from '../Ads/NtvBb';


function Artikel({teaser, adContent}) {


   
  
   // const mrAd=useRef(null)


  useEffect(()=>{

 
  
  
},[])






  return (
    <>
   <div className='ntv-article-day'>
    
  {teaser ? <SetTeaser/> : <div></div>}

  

   </div>


   <div className='ntv-article-head'>nachrichten</div>

   {teaser ? <SetMain adContent={adContent}/> : <SetMain2/>}
   


       
   </>
 
  );


  }


  function SetMain2(){
    return Data2.rss.channel.item.map((item,index)=>{
        const [beforeColon, afterColon] = item.title.split(':');
        const transformedLink=transformLink(item.enclosure._url)
       
            return(
                <div className='ntv-article-day-container-main' key={index}>
                <div className='ntv-article-day-img-container-main'>
                <img className='ntv-article-day-img' src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
                <img className='ntv-article-day-img_mobile-main' src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
             
                </div>
        
                <div className='ntv-article-day-text-container-main'>
                <div className='ntv-article-main-theme'> {item.category}</div>
                <div className='ntv-article-main-desk-head'>
                {beforeColon}
                </div>

                <div className='ntv-article-main-desk-text'>{afterColon}</div>

                <div className='ntv-article-main-desk-desc-main'>{item.description}</div>     
                </div>
        
            </div>
            )
        
      
    })
    

   }

   
   function transformLink(originalLink) {
    return originalLink
        .replace('/img/incoming/crop', '/img/') 
        .replace('-cImg_4_3-w250', '/17-6/1136') 
        .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}



  function SetMain({adContent}){
    const [searchParams] = useSearchParams();
    const mr = searchParams.get('mr');
    const paramName = Array.from(searchParams.keys())[0];
   
    return Data.rss.channel.item.map((item,index)=>{
        const [beforeColon, afterColon] = item.title.split(':');
        const transformedLink=transformLink(item.enclosure._url)
        if(index>4 && index<10){

            if(index==5 && adContent!=null){
    
                return(

                   <NtvMr adContent={adContent} key={index}/>
                )
            }
            else{

            return(
                <div className='ntv-article-day-container-main' key={index}>
                <div className='ntv-article-day-img-container-main'>
                <img className='ntv-article-day-img' src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
                <img className='ntv-article-day-img_mobile-main' src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
             
                </div>
        
                <div className='ntv-article-day-text-container-main'>
                <div className='ntv-article-main-theme'> {item.category}</div>
                <div className='ntv-article-main-desk-head'>
                {beforeColon}
                </div>

                <div className='ntv-article-main-desk-text'>{afterColon}</div>

                <div className='ntv-article-main-desk-desc-main'>{item.description}</div>     
                </div>
        
            </div>
            )
        }
        }

       
        
        else{
        return(
            <div className='ntv-article-main_container' key={index+10}>

            <div className='ntv-article-main_img-container'>
            <img src={transformedLink} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
            </div>

            <div className='ntv-article-main-theme'>
             {item.category}
            </div>

            <div className='ntv-article-main-desk-head'>
                {beforeColon}
            </div>

         <div className='ntv-article-main-desk-text'>{afterColon}</div>
                   
         <div className='ntv-article-main-desk-desc'>{item.description}</div>        
           
 

            </div>
        )
    }
    })
    

   }

   function transformLinkTeaser(originalLinkTeaser) {
    return originalLinkTeaser
    .replace('/img/incoming/crop', '/img/') 
    .replace('-cImg_4_3-w250', '/17-6/320') 
    .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}


  function SetTeaser(){
    return DayData.rss.channel.item.slice(0,3).map((item,index)=>{
        const [beforeColon, afterColon] = item.title.split(':');
        const transformedLinkTeaser=transformLinkTeaser(item.enclosure._url)
        console.log(item.enclosure._url)
        console.log(transformedLinkTeaser)
        return(
            <div className='ntv-article-day-container' key={index}>
            <div className='ntv-article-day-img-container'>
            <img className='ntv-article-day-img' src={transformedLinkTeaser} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
            <img className='ntv-article-day-img_mobile' src={transformedLinkTeaser} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
         
            </div>
    
            <div className='ntv-article-day-text-container'>
            <div className='ntv-article-day-text-head'>{beforeColon}</div>
            <div className='ntv-article-day-text'>{afterColon}</div>
            </div>
    
        </div>
        )
    })
    

    

   }




export default Artikel;
