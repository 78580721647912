import {React, useEffect, useState, Component, useRef} from "react";
import '../../../styles.scss'
import {useParams, useSearchParams  } from 'react-router-dom';
import Logo from '../../assets/vox_logo.png'


function VoxBottom(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const tvad = searchParams.get('tvad');


  useEffect(()=>{

  },[])

 

  


    return(
    
      

<div className="vox-bottombumper_container">

<div className="vox-bottombumper-text_container">
    <p id="vox-bottombumper-text1">Bunte Vielfalt. Das volle Programm.</p>
    <p id="vox-bottombumper-text2">Nach diesem spot geht es weiter.</p>
</div>

<div className="vox-bottombumper-logo_container">
    <img src={Logo}/>
</div>

</div>

    
    
       
    )



}

export default VoxBottom;