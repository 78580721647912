import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'
import './styles.scss'


function GalaMr({adContent}) {


  const mrAd=useRef(null)
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)
  const test=useRef(null)


  useEffect(()=>{

  
    const callback=(entries, observerMr)=>{
      entries.forEach(entry=>{
        const {target, isIntersecting}=entry
        if(isIntersecting){
          if(visibilityInterval.current){
            clearInterval(visibilityInterval.current)
          }
  
          visibilityInterval.current=setInterval(()=>{
            adVisibilityTime.current++
            //console.log(adVisibilityTime.current)
             //eslint-disable-next-line
        sendResultFieldData({5:String("visibility Gala-medrec " + Ads.mr[adContent].ad +" : "+ adVisibilityTime.current)})
          },1000)
        }
    
        else{
          //console.log("STOP")
          clearInterval(visibilityInterval.current)
           //eslint-disable-next-line
        sendResultFieldData({5:String("visibility Gala-medrec " + Ads.mr[adContent].ad +" : "+ adVisibilityTime.current)})
        }
    
    
      })
    }
    
    const options={
      threshold:0
    }
    
    const observerMr=new IntersectionObserver(callback, options)
    
    observerMr.observe(mrAd.current)

    
  
},[])






  return (
   <div className='ad_klicks ntv-ad-medrec' ref={mrAd} data-click-tracking={Ads.mr[adContent].ad}>

    <div className='ntv-ad-medrec_container'>
    {Ads.mr[adContent].type == 'mp4' ? <video src={Ads.mr[adContent].url} loop playsInline muted autoPlay/> : <img src={Ads.mr[adContent].url}/>}   
    </div>
  
   </div>
 
  );


  }




export default GalaMr;
