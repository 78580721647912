import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Dummy from '../assets/img/mainDummy.jpg'
import './styles.scss'
import NoImg from '../../../../Images/noimg.jpg'

function ArtikelHighLight({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    
        
        <div className='gala-hightlight_article2_container'>

        <div className='gala-hightlight_article2'>
      <div className='gala-hightlight_article2-text_container'>
      <div className='gala-hightlight_article2-text_title'>{category}</div>
      <div className='gala-hightlight_article2-text_desc'>{title}</div>
      </div>
      <div className='gala-hightlight_article2-img_container'><img src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/></div>
      </div> 
      </div>

   
 
  );


  }








export default ArtikelHighLight;
