import React,{useEffect, useState, Component, useRef} from 'react';
import './style.scss'
import MainImg from '../assets/ads_define/nrwbank/original.webp'
import HotelImg from '../assets/ads_define/nrwbank/original_hotel.webp'
import Arrow from '../assets/ads_define/nrwbank/arrow.svg'
import defineLogo from '../assets/ads_define/nrwbank/define_conative.svg'

function NrwBank() {

const MainImgContainerTop=useRef(null)
const adContainerFooter=useRef(null)
const MainImgContainer=useRef(null)
const adNrwContainer=useRef(null)
const adNrwTextContainer=useRef(null) 
const expendAd=useRef(null) 
const closeAd=useRef(null) 
const adExpend=useRef(null) 
 





  useEffect(()=>{


    
  
},[])


const expandAd=()=>{


adNrwContainer.current.classList.remove("nrwbank_ad_block_contianer")
adNrwContainer.current.classList.add("nrwbank_ad_block_contianer-expand")

adNrwTextContainer.current.classList.remove("nrwbank_ad_block-text_container")
adNrwTextContainer.current.classList.add("nrwbank_ad_block-text_container-expand")

adExpend.current.style.display="flex"
MainImgContainer.current.style.width="100%"
expendAd.current.style.display="none"
closeAd.current.style.display="block"




}
const closeExpandAd=()=>{

  adNrwContainer.current.classList.add("nrwbank_ad_block_contianer")
  adNrwContainer.current.classList.remove("nrwbank_ad_block_contianer-expand")
  
  adNrwTextContainer.current.classList.add("nrwbank_ad_block-text_container")
  adNrwTextContainer.current.classList.remove("nrwbank_ad_block-text_container-expand")
  
  adExpend.current.style.display="none"
  MainImgContainer.current.style.width="38%"
  expendAd.current.style.display="flex"
  closeAd.current.style.display="none"

  adContainerFooter.current.scrollIntoView({block:"end"});

}



  return (
   <div className='nrwbank_ad'>
    <div className='nrwbank_ad_container' ref={MainImgContainerTop}>
      <div className='nrwbank_container_top'>
        <div className='nrwbank_container_top-title'>Förderprogramme der NRW.BANK für Unternehmensnachfolgen</div>
        <div className='nrwbank_container_top-ad'>Anzeige</div>
      </div>
      <div className='nrwbank_ad_block_contianer' ref={adNrwContainer}>
        <div className='nrwbank_ad_block-img_container' ref={MainImgContainer}>
          <img src={MainImg}/>
        </div>
        <div className='nrwbank_ad_block-text_container'  ref={adNrwTextContainer}>
        Viele Firmeninhaber*innen sind auf der Suche nach einer Nachfolgerin oder einem Nachfolger. Die NRW.BANK bietet Förderprogramme und Beratung für Unternehmensnachfolgen, wie im Fall von Manuela Baier. Die gebürtige Bonnerin machte sich mit dem schon bestehenden Landhotel Kallbach selbstständig und profitierte so von den Vorteilen einer Gründung durch Nachfolge.
        </div>
      </div>

    <div className='nrwbank_ad_block_contianer_expand' ref={adExpend}>

 
 <div>
    <div className='nrwbank_container_top-title'>Landhotel Kallbach</div>
    <div className='nrwbank_ad_block_contianer-expand'>
        <div className='nrwbank_ad_block-img_container_expand'>
          <img src={HotelImg}/>
        </div>
        <div className='nrwbank_ad_block-text_container-expand'>
        Für Manuela Baier war es nach 30 Jahren im Berufsleben der Start in die Selbstständigkeit: die Übernahme des Landhotel Kallbach in der Eifel, eine Gründung durch Nachfolge. Sie „wollte mit knapp 50 Jahren noch einmal etwas Neues anfangen“. Seitdem hat die Hotelbesitzerin zwei Lockdowns und eine Schließung wegen Überschwemmungen überstanden, Ferienwohnungen ergänzt und die Vier-Sterne-Superior-Kategorie erreicht. Das Landhotel bietet über 130 Gästen Platz und beschäftigt ein 30-köpfiges Team.
        </div>
      </div>

      </div>

<div>
      <div className='nrwbank_container_top-title'>So hat die NRW.BANK unterstützt</div>
    <div className='nrwbank_ad_block_contianer-expand'>
      
        <div className='nrwbank_ad_block-text_container-expand'>
        Auf der Finanzierungssuche wandte sich Manuela Baier an ihre Hausbank. Diese vermittelte ihr für den Kauf und den Ausbau des Hotels verschiedene Förderdarlehen, unter anderem von der NRW.BANK. „Die Unterstützung durch die Förderdarlehen war sensationell“, betont Manuela Baier. Neben den niedrigen Zinssätzen schätzte sie die Möglichkeit des späteren Rückzahlungsbeginns. „Die tilgungsfreien Anfangsjahre haben mir sehr geholfen, liquide zu bleiben und mein Unternehmen zu stabilisieren.“
        </div>
      </div>

      </div>

<div>
      <div className='nrwbank_container_top-title'>NRW.BANK: einfache Finanzierung für innovative Ideen</div>
    <div className='nrwbank_ad_block_contianer-expand'>
      
        <div className='nrwbank_ad_block-text_container-expand'>
        Die NRW.BANK unterstützte Manuela Baier mit den Förderprogrammen NRW.BANK.Universalkredit und NRW.BANK.Gründung und Wachstum.

        <ul>
          <li>Der NRW.BANK.Universalkredit kann für unterschiedlichste Investitionen eingesetzt werden.</li>
          <li>Das Programm NRW.BANK.Gründung und Wachstum finanziert Investitionen und Betriebsmittel bei Gründungen, Unternehmensnachfolgen und Festigungsmaßnahmen.</li>
        </ul>
        </div>
      </div>

      </div>

      <div>
    <div className='nrwbank_ad_block-text_container-expand-link'>Lassen Sie sich gern von den Expertinnen und Experten der NRW.BANK zu den Fördermöglichkeiten beraten.</div>

      </div>

      <div>
      <div className='nrwbank_container_top-title'>Förderung kann so einfach sein – NRW.BANK</div>
    <div className='nrwbank_ad_block_contianer-expand'>
      
        <div className='nrwbank_ad_block-text_container-expand'>
        Die NRW.BANK ist die Förderbank für Nordrhein-Westfalen. In enger Partnerschaft mit ihrem Eigentümer, dem Land NRW, trägt sie dazu bei, dass Gründende die passende Starthilfe bekommen. Denn: Keine gute Idee in Nordrhein-Westfalen soll an der Finanzierung scheitern. Um dieses Ziel zu erreichen, steht die NRW.BANK Gründerinnen und Gründern mit Förderdarlehen, Eigenkapital, Zuschüssen und Beratung zur Seite.

        </div>
      </div>

      </div>


      <div>
      <div className='nrwbank_rect_cta-container'>
      <div className='nrwbank_rect_cta'>
        <div>Lesen Sie hier Tipps, wie Sie Ihre Nachfolge und Gründung sicher finanzieren.  </div>
        </div>
      </div>

      </div>
   


    </div>  


    <div className='nrwbank_ad-ctaBtn_contianer' ref={expendAd}>
      <div className='nrwbank_ad-ctaBtn'  onClick={expandAd}>
      Mehr zur Förderung und Manuela Baiers Nachfolgegeschichte.
      </div>
      <img src={Arrow}/>
    </div>


    <div className='nrwbank_ad-footer_container' ref={adContainerFooter}>
      <div className='nrwbank_ad-footer'>AI-driven <img src={defineLogo}/></div>
      <div className='nrwbank_ad-footer-closeBtn_expand' ref={closeAd} onClick={closeExpandAd}>Schließen</div>
      </div>
    </div>
   </div>
 
  );


  
   
  }




export default NrwBank;
