import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import Dummy2 from '../assets/img/dummy.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'


function ArtikelTeaser({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
    
      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <div className='gala-article-main-desk-head'>
                {category}
                </div>

                <div className='gala-article-main-desk-text'> {title}</div>
  
                
                </div>
        
                
            </div>
          
            

   </>
 
  );


  }






export default ArtikelTeaser;
