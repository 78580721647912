import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Dummy from '../assets/img/mainDummy.jpg'
import Data from '../../../../data/inpage/gala/data.json'
import './styles.scss'
import { height, positions, width } from '@mui/system';
import NoImg from '../../../../Images/noimg.jpg'

function ArtikelHighLight({img,  title, text, action}) {


  



useEffect(()=>{

  const swiperEl = document.querySelector('swiper-container');

   
  swiperEl.swiper.params.pagination.renderBullet=function(index, className){
    const labels=["XMAS DEAL", "BACKEN", "PLUS"]
    return `<span class="${className}">${labels[index]}</span>`;
  }
    
  swiperEl.swiper.pagination.render();
  swiperEl.swiper.pagination.init();
},[])






  return (
    
    <>
     <swiper-slide style={{display:'flex', justifyContent: 'center',  position:'relative'}}>
    <div className='chef-hightlight-article_slide'>
    
    <div className='chef-hightlight_article-title-mobile'>{title}</div>
    <div className='chef-hightlight_article-img_container'>
      <img src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
    </div>
    
    <div className='chef-hightlight_article-text_container'>
      <div className='chef-hightlight_article-title'>{title}</div>
      <div className='chef-hightlight_article-text'>{text}</div>
      <div className='chef-hightlight_article-action'>{action}<span>→</span></div>
    </div>
  
   

      </div>
      </swiper-slide>
      </>
  );




  }








export default ArtikelHighLight;
