import React,{useEffect, useState, Component, useRef} from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import '../BodyStyle/styles.scss'



function MobileFooter() {

    const footerRef=useRef(null)
    var scrollPos=0
    var newScrollPos=0
  
    useEffect(()=>{
     
    },[])
    
    window.addEventListener("scroll",function() {
      
      newScrollPos=window.scrollY
  
      if(newScrollPos>scrollPos){
        footerRef.current.style.transform="translateY(200%)"
      
        scrollPos=newScrollPos
    
      }
  
      if(newScrollPos<scrollPos){
        footerRef.current.style.transform="translateY(0%)"
        scrollPos=newScrollPos
        
    
      }

     
     
    }) 

   
   

  return (
    <>

    
 <div className='LinkedFooterMobile'ref={footerRef}>

 <div className='LinkedIcon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="#666666"  width="24" height="24" focusable="false">
      <path d="M23 9v2h-2v7a3 3 0 01-3 3h-4v-6h-4v6H6a3 3 0 01-3-3v-7H1V9l11-7 5 3.18V2h3v5.09z"></path>
    </svg>Start</div>
    <div className='LinkedIcon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="#666666"  width="24" height="24" focusable="false">
      <path d="M12 16v6H3v-6a3 3 0 013-3h3a3 3 0 013 3zm5.5-3A3.5 3.5 0 1014 9.5a3.5 3.5 0 003.5 3.5zm1 2h-2a2.5 2.5 0 00-2.5 2.5V22h7v-4.5a2.5 2.5 0 00-2.5-2.5zM7.5 2A4.5 4.5 0 1012 6.5 4.49 4.49 0 007.5 2z"></path>
    </svg>Ihr Netzwerk</div>
    <div className='LinkedIcon'><AddBoxIcon style={{color:'#666666'}}/>Beitrag</div>
    <div className='LinkedIcon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="#666666"  width="24" height="24" focusable="false">
      <path d="M22 19h-8.28a2 2 0 11-3.44 0H2v-1a4.52 4.52 0 011.17-2.83l1-1.17h15.7l1 1.17A4.42 4.42 0 0122 18zM18.21 7.44A6.27 6.27 0 0012 2a6.27 6.27 0 00-6.21 5.44L5 13h14z"></path>
    </svg>Mitteilungen</div>
    <div className='LinkedIcon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="#666666"  width="24" height="24" focusable="false">
      <path d="M17 6V5a3 3 0 00-3-3h-4a3 3 0 00-3 3v1H2v4a3 3 0 003 3h14a3 3 0 003-3V6zM9 5a1 1 0 011-1h4a1 1 0 011 1v1H9zm10 9a4 4 0 003-1.38V17a3 3 0 01-3 3H5a3 3 0 01-3-3v-4.38A4 4 0 005 14z"></path>
    </svg>Jobs</div>
    
  
    
    </div>
    
    
   
    </>
  );
}

export default MobileFooter;
