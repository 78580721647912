import {React, useEffect, useState, Component, useRef} from "react";
import '../../../styles.scss'
import {useParams, useSearchParams  } from 'react-router-dom';



function RtlBottom(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const tvad = searchParams.get('tvad');


  useEffect(()=>{

  },[])

 

  


    return(
        <div className="rtl-bottombumper_container" id="test">

            <div className="rtl-bottombumper-text_container">
                <p>Wir lieben Fersehen.</p>
                <p>Nach diesem Spot geht es weiter.</p>
            </div>

            <div className="rtl-bottombumper-logo_container">
                <div className="rtl-bottombumper-logo_item" id="rtl-bottombumper-logo_itemR"><span>R</span></div>
                <div className="rtl-bottombumper-logo_item" id="rtl-bottombumper-logo_itemT"><span>T</span></div>
                <div className="rtl-bottombumper-logo_item" id="rtl-bottombumper-logo_itemL"><span>L</span></div>
            </div>

        </div>
       
    )



}

export default RtlBottom;