import React,{useEffect, useState, Component, useRef} from 'react';
import ArticleDefine from './ArticleDefine';
import { useParams} from "react-router-dom";
import '../Styles/styles.scss'

function Index() {

 const {article}= useParams()
  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='define-index_container'>
    <SetArticle/>
   </div>
 
  );


  function SetArticle(){
    switch(article){
        case "1":
      return<ArticleDefine/>

      case "2":
        return<ArticleDefine/>

      case "3":
        return<ArticleDefine/>

      case "4":
        return<ArticleDefine/>
     
    }
}
  
   
  }




export default Index;
