import React,{useEffect, useState, Component} from 'react';
import logo from './logo.svg';
import './App.scss';
import Head from './ComponentsMob/Head/Head.jsx'

import DemoMod1 from './Components/Body/DemoMod1/Body'
import DemoMod2 from './Components/Body/DemoMod2/Body'
import DemoMod3 from './Components/Body/DemoMod3/Body'
import Body from './Components/Body/Mod1/Body.jsx'
import {Routes,Route} from "react-router-dom";
import BodyStart from './Components/Body/BannerReweka/BodyStart.jsx'
import BodyReweka from './Components/Body/BannerReweka/Body/Body.jsx'
import RewekaHead from './Components/Body/BannerReweka/Head/Head'
import BodyMobile from './ComponentsMob/Body/Body'
import BodyMobile2 from './ComponentsMob/Cars/Body'
import SubpageAuto from './ComponentsMob/Cars/ads/AdAudi5.jsx'


import Index from './Component_ai/Body/Index.jsx';
import Subpage from './Component_ai/Body/Subpage.jsx'

// LinkedIn

import LinkedHead from './ComponentsLin/Head/Head.jsx'
import BodyLinked from './ComponentsLin/Body/Body'



// Mediatheken

/*
import IndexNetfl from './ComponentsMedia/netflix/Index.jsx'


import VideoPlayer from './ComponentsMedia/netflix/Components/Video/VideoPlayer.jsx'

import Adblock from './ComponentsMedia/netflix/Components/Video/Adblock.jsx';
*/


import IndexPrime from './ComponentsMedia/prime/Index.jsx'
import VideoPlayerPrime from './ComponentsMedia/prime/Components/Video/VideoPlayer.jsx'

//Adcheck Mob-Native 2024
import MobNativeHead from './Adcheck2/ComponentsMobNative/Header/Head.jsx';
import MobNativeBody from './Adcheck2/ComponentsMobNative/Body/Body.jsx';

import MobNativeSmileBody from './Adcheck2/ComponentsMobNative/Body/BodySmile.jsx';
import MobNativeSmileBodyAdvr from './Adcheck2/ComponentsMobNative/Body/BodyAdvrSmile.jsx';
import MobNativeSmileBodyLand from './Adcheck2/ComponentsMobNative/Body/BodySmileLand.jsx';

import MobNativeBodyAdvr from './Adcheck2/ComponentsMobNative/Body/BodyAdvr.jsx';
import MobNativeBodyLand from './Adcheck2/ComponentsMobNative/Body/BodyLand.jsx';


/* 
//Adcheck Gen-AI 2024

import GenAIHead from './Adcheck2/ComponentsGenAI/Header/Head.jsx'
import GenAIBody from './Adcheck2/ComponentsGenAI/Body/Body.jsx'


import GenAIGosHead from './Adcheck2/ComponentsGenAI/Header/HeadGos.jsx'
import GenAIGosBody from './Adcheck2/ComponentsGenAI/Body/BodyGos.jsx'

import GenAIGosArticle from './Adcheck2/ComponentsGenAI/Body/article/ArticleGos.jsx'
import GenAIOnlineArticle from './Adcheck2/ComponentsGenAI/Body/article/ArticleOnline.jsx'
*/

// Autoscout

import AutoscoutHead from './componentsAutoScout/head/Head.jsx';
import BodyAutoScout from './componentsAutoScout/body/BodyAutoScout.jsx';
import SubBodyAutoScout from './componentsAutoScout/body/components/subpage/SubpageBody.jsx'


// Tik Tok
import TikTokIndex from './ComponentsMedia/tiktok/Index.jsx'
import TikTokTopView from './ComponentsMedia/tiktok/Components/Content/TiktokTopView.jsx'
import TikTokInFeed from './ComponentsMedia/tiktok/Components/Content/TiktokInFeed.jsx'
import TikTokContent from './ComponentsMedia/tiktok/Components/Content/TiktokContent.jsx'


//Facebook

import FaceBookContent from './ComponentsMedia/facebook/Components/Content/FaceBookContent.jsx';
import FaceBookIndex from './ComponentsMedia/facebook/Index.jsx'
import FaceBookInFeed from './ComponentsMedia/facebook/Components/Content/FaceBookInFeed.jsx'
import FaceBookInStream from './ComponentsMedia/facebook/Components/Content/FaceBookInStream.jsx';


// TV

import TvIndex from './tv/Index.jsx';
import ATvIndex from './tv/Components/Content/etv/ETvIndex.jsx';
import ATvspot from './tv/Components/Content/etv/ETvspot.jsx';
import TVIndex from './tv/Components/Content/tv/TvIndex.jsx';
import Tvspot from './tv/Components/Content/tv/Tvspot.jsx';


//Define
import DefineIndex from './Adcheck2/ComponentsDefine/Article/Index.jsx';
import DefineHead from './Adcheck2/ComponentsDefine/Header/Head.jsx'
import ArticleDefine from './Adcheck2/ComponentsDefine/Article/ArticleDefine.jsx';


//Inpage
import InpageIndex from './Adcheck2/ComponentsInpage/Ntv/Index.jsx'
import InpageSternIndex from './Adcheck2/ComponentsInpage/Stern/Index.jsx'
import InpageGalaIndex from './Adcheck2/ComponentsInpage/Gala/Index.jsx'
import InpaChefIndex from './Adcheck2/ComponentsInpage/Chef/Index.jsx'


// externer js script einfügen

/* 
export function AddLibrary() {
  const script = document.createElement('script')
    script.src="https://kunde.trend-research.de/multimedia/plugin/2021_adcheck.js"
    script.async=true
    document.body.appendChild(script)
}
    */

function App() {

  

  useEffect(()=>{
    
    document.title = `OnlineShop`;


    let urlParam=document.location.search;
    let searchParams=new URLSearchParams(urlParam)
    let show=searchParams.get("show")

    
    
    const script = document.createElement('script')
    script.src="https://kunde.trend-research.de/multimedia/plugin/2021_adcheck.js"
    script.defer=false
    document.body.appendChild(script)



    return()=>{
      document.body.removeChild(script)
    }
  
   
},[])

  return (
    <div className="Main" id="main">
     
   
    
      
      <Routes>
     
      <Route path="demo=1" element={ <DemoMod1/>}/>
      <Route path="demo=2" element={ <DemoMod2/>}/>
      <Route path="demo=3" element={ <DemoMod3/>}/>
      <Route path="rewe" element={ <Body/>}/>
        {/* <Route path='mobile' element={<Head/>}>
      <Route index element={ <BodyMobile/>}/>
     {/*<Route path="cars" element={<BodyMobile2/>}/>
      <Route path="cars" element={<Subpage/>}/>
       </Route>
        */}

      <Route path='linked' element={<LinkedHead/>}>
      <Route index element={ <BodyLinked/>}/>
    
      
       </Route>
    
     


<Route path="context" element={ <Index/>}/>
<Route path="context/subpage" element={ <Subpage/>}/>

<Route path='mobile' element={<Head/>}>
      <Route index element={ <BodyMobile/>}/>
      <Route path="cars" element={<SubpageAuto/>}/>
       </Route>

       <Route path='autoscout/:ads' element={<AutoscoutHead/>}>
      <Route index element={<BodyAutoScout/>}/>
      <Route path="items" element={<SubBodyAutoScout/>}/>
       </Route>


<Route path="reweka" element={ <RewekaHead/>}>
  <Route index element={ <BodyStart/>}/>
  <Route path="rewekashop" element={<BodyReweka/>}/>
</Route>

{/*  Mediatheken Route
<Route path="netfl" element={ <IndexNetfl/>}/>
<Route path="netfl/watch/:vidid" element={<VideoPlayer/>}/>
<Route path="netfl/test" element={ <Adblock/>}/>
*/}
<Route path="prime" element={ <IndexPrime/>}/>
<Route path="prime/watch/:vidid" element={<VideoPlayerPrime/>}/>



<Route path="mob-native" element={<MobNativeHead/>}>
  <Route path="grill/:adt" element={ <MobNativeBody/>}/>
 
  <Route path="grill/:adt/advr" element={ <MobNativeBodyAdvr/>}/>
  <Route path="smile/:adt" element={ <MobNativeSmileBody/>}/>
  <Route path="smile/:adt/advr" element={ <MobNativeSmileBodyAdvr/>}/>
</Route>

<Route path="/mob-native/grill/:adt/land" element={ <MobNativeBodyLand/>}/>

<Route path="/mob-native/smile/:adt/land" element={ <MobNativeSmileBodyLand/>}/>

{/* 
<Route path="gen-ai/online/:txt" element={<GenAIHead/>}>

  <Route index element={ <GenAIOnlineArticle/>}/>


</Route>

<Route path="gen-ai/gos/:txt" element={<GenAIGosHead/>}>

  <Route index element={ <GenAIGosArticle/>}/>


</Route>
*/}

<Route path="tiktok" element={ <TikTokIndex/>}>
  <Route index element={ <TikTokContent/>}/>  
  <Route path="tiktoktopview/:tiktokad" element={ <TikTokTopView/>}/>  
  <Route path="tiktokinfeed/:tiktokad" element={ <TikTokInFeed/>}/>  
  </Route>


  <Route path="fb" element={ <FaceBookIndex/>}>
  <Route index element={ <FaceBookContent/>}/>  
  <Route path="fbinfeed/:fbad" element={ <FaceBookInFeed/>}/>  
  <Route path="fbinstream/:fbad" element={ <FaceBookInStream/>}/>  
  </Route>


  <Route path="tv" element={ <TvIndex/>}>
  <Route index element={ <TvIndex/>}/>
  <Route path="atv/" element={ <ATvIndex/>}/>  
  <Route path="atv/:tv/spot" element={ <ATvspot/>}/>  
  <Route path="tv/" element={ <TVIndex/>}/>  
  <Route path="tv/:tv/spot" element={ <Tvspot/>}/>  
  </Route>


  <Route path="define/:article" element={ <DefineHead/>}>

<Route index element={ <DefineIndex/>}/>


  </Route>

  <Route path="inpage/ntv" element={ <InpageIndex/>}/>
  <Route path="inpage/stern" element={ <InpageSternIndex/>}/>
  <Route path="inpage/gala" element={ <InpageGalaIndex/>}/>
  <Route path="inpage/chef" element={ <InpaChefIndex/>}/>

      </Routes>
     {/*{AddLibrary()}*/}
    </div>
  );
}

export default App;
