import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Pic1 from '../assets/img/1.jpg'
import Pic2 from '../assets/img/2.jpg'
import Pic3 from '../assets/img/3.jpg'
import './styles.scss'


function ArtikelTeaserFooter() {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
     <div className="gala-article-container">
    
      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic1}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <div className='gala-article-main-desk-head'>
                gala
                </div>

                <div className='gala-article-main-desk-text'> Inhaltsverzeichnis</div>
  
                
                </div>
        
                
            </div>



      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic2}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <div className='gala-article-main-desk-head'>
                Web, App, Pushnachrichten + Feeds
                </div>

                <div className='gala-article-main-desk-text'> So erhalten Sie Ihre GALA-News immer und überall</div>
  
                
                </div>
        
                
            </div>



      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic3}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <div className='gala-article-main-desk-head'>
                Alles über Ihre Lieblingsstars
                </div>

                <div className='gala-article-main-desk-text'> Daily Newsflash</div>
  
                
                </div>
        
                
            </div>
          
            
            </div>
   </>
 
  );


  }






export default ArtikelTeaserFooter;
