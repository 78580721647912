import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import GalaLogo from '../assets/logos/logo.svg'

import './styles.scss'


function Footer() {



  



  useEffect(()=>{


    
  
},[])






  return (

   <div className='gala-footer'>

<div className='gala-footer_container'>
<div className='gala-footer-logo_container'>
  <img src={GalaLogo}/>
</div>

<ul className='gala-footer-nav_container'>
  <li>Für Gala anmelden</li>
  <li>Mein Gala-Konto</li>
  <li>Impressum</li>
  <li>Kontakt</li>
  <li>Datenschutzhinweise</li>
  <li>Datenschutz-Einstellungen</li>
  <li>AGB</li>
  <li>Werbung</li>
</ul>

<div className='gala-footer-social-icons_container'>
  
</div>

</div>
   

   </div>
 
  );


  }




export default Footer;
