import {React, useEffect, useState, Component, useRef} from "react";
import '../styles.scss'
import Data from '../../../data/tv/data.json'
import Ads from '../../../data/tv/ads_tv.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams  } from 'react-router-dom';


function TvBlackScreen(){

  const kanalInfo=useRef(0)
  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const kanal = searchParams.get('k');

  useEffect(()=>{
   kanalInfo.current.style.opacity=1
  },[])


  const getime=()=>{
    const time=new Date()
    const hour=String(time.getHours()).padStart(2, '0')
    const min=String(time.getMinutes()).padStart(2, '0')

    return `${hour}:${min}`
  }

  const settnewTime=()=>{
    const time=new Date()
    time.setHours(time.getHours()+2)
    const hour=String(time.getHours()).padStart(2, '0')
    const min=30

    return `${hour}:${min}`
  }


    return(
    
        <div className="tv_etv_blackscreen">

         <div className="tv_etv_blackscreen-kanalInfo-container" ref={kanalInfo}>
          <div className="tv_etv_blackscreen-kanalInfo-kanalName-container">
          <div className="tv_etv_blackscreen-kanalInfo-kanalName">
            <span>{Data.data[tv]._attributes.kanalnum}</span>
            <div>{Data.data[tv]._attributes.typ}</div>
          </div>
          </div>
          <div className="tv_etv_blackscreen-kanalInfo">
            <div className="tv_etv_blackscreen-kanalInfo-Tv_name">{Data.data[tv]._attributes.programm}</div>
         
            <div className="tv_etv_blackscreen-kanalInfo-time">{`${getime()}-${settnewTime()}`}</div>
            <div className="tv_etv_blackscreen-kanalInfo-timeline-container"><div className="tv_etv_blackscreen-kanalInfo-timeline"><div className="tv_etv_blackscreen-kanalInfo-timeline-time"></div></div></div>
            <div className="tv_etv_blackscreen-kanalInfo-now">&lt; Now &gt;</div>
            <div className="tv_etv_blackscreen-kanalInfo-info"><div className="tv_etv_blackscreen-kanalInfo-infobtn">i</div><span>Info</span></div>
          </div>
          <div className="tv_etv_blackscreen-kanalInfo-settings">
            <div className="tv_etv_blackscreen-kanalInfo-settings-time">{getime()}</div>
            <div className="tv_etv_blackscreen-kanalInfo-settings-channel">All DVB-C Channels</div>
            <div className="tv_etv_blackscreen-kanalInfo-settings-catagory"><div className="tv_etv_blackscreen-kanalInfo-settings-blue"></div><span>Change category</span></div>
          </div>
         </div>

        </div>
    
        
    )
}

export default TvBlackScreen;