import React,{useEffect, useState, Component, useRef} from 'react';
import Wolke from '../assets/logos/wolke.png'
import './styles.scss'


function Panel() {


  



  useEffect(()=>{

 
    CalcPercent()
  
},[])


  function CalcPercent(){
    document.querySelectorAll('.ntv-panel-stock-item_value').forEach((item)=>{
      const value=item.querySelector('span')
     
      if(parseFloat(value.textContent)<0){
        value.style.color="red"
      }
      else{
         value.style.color="green"
      }
      
    })
  }



  return (
   <div className='ntv-panel'>

    <div className='ntv-panel-container'>

<div className='ntv-panel-stock-container'>
<h3>börsenkurse</h3>
<div className='ntv-panel-stock-items_container'>

<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>DAX</div>
<div className='ntv-panel-stock-item_value'>19.251 <span>-0.3%</span></div>
<div className='ntv-panel-stock-item_name'>MDAX</div>
<div className='ntv-panel-stock-item_value'>256.548 <span>0.2%</span></div>
<div className='ntv-panel-stock-item_name'>ESX</div>
<div className='ntv-panel-stock-item_value'>4.816 <span>-0.6%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>DAX Ind</div>
<div className='ntv-panel-stock-item_value'>19.244 <span>0.0%</span></div>
<div className='ntv-panel-stock-item_name'>DOW Ind</div>
<div className='ntv-panel-stock-item_value'>43.613 <span>-0.4%</span></div>
<div className='ntv-panel-stock-item_name'>ESX Ind</div>
<div className='ntv-panel-stock-item_value'>4.814 <span>-0.1%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>EUR</div>
<div className='ntv-panel-stock-item_value'>1,0569 <span>0.3%</span></div>
<div className='ntv-panel-stock-item_name'>Öl</div>
<div className='ntv-panel-stock-item_value'>68,34 <span>-0.2%</span></div>
<div className='ntv-panel-stock-item_name'>Gold</div>
<div className='ntv-panel-stock-item_value'>2.568 <span>0.0%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>Bitcion</div>
<div className='ntv-panel-stock-item_value'>89.452 <span>2.0%</span></div>
<div className='ntv-panel-stock-item_name'>Silber</div>
<div className='ntv-panel-stock-item_value'>30,64 <span>0.5%</span></div>
<div className='ntv-panel-stock-item_name'>Platinum</div>
<div className='ntv-panel-stock-item_value'>897 <span>0.6%</span></div>
</div>

<div className='ntv-panel-stock-item_container_mobile'>
<div className='ntv-panel-stock-item_name'>DAX</div>
<div className='ntv-panel-stock-item_value'>19.251 <span>-0.3%</span></div>
<div className='ntv-panel-stock-item_name'>MDAX</div>
<div className='ntv-panel-stock-item_value'>256.548 <span>0.2%</span></div>
</div>
<div className='ntv-panel-stock-item_container_mobile'>
<div className='ntv-panel-stock-item_name'>DAX Ind</div>
<div className='ntv-panel-stock-item_value'>19.244 <span>0.0%</span></div>
<div className='ntv-panel-stock-item_name'>DOW Ind</div>
<div className='ntv-panel-stock-item_value'>43.613 <span>-0.4%</span></div>
</div>

</div>



</div>

<div className='ntv-panel-wetter-container'>

<h3>WETTER</h3>
<div className='ntv-panel-wetter-items_container'>

<div className='ntv-panel-wetter-item_container ntvWetterImg-container'>
<div className='ntv-panel-wetter-img_container'><img src={Wolke}/></div>
<div className='ntv-panel-wetter-item-temp'>8°</div>
</div>
<div className='ntv-panel-wetter-item_container'>
<div className='ntv-panel-wetter-item'>
<div className='ntv-panel-wetter-item_day'>Fr.</div>
<div className='ntv-panel-wetter-item_temp1'><span>10°</span>/3°</div>
</div>
<div className='ntv-panel-wetter-item'>
<div className='ntv-panel-wetter-item_day'>Sa.</div>
<div className='ntv-panel-wetter-item_temp1'><span>9°</span>/4°</div>
</div>
<div className='ntv-panel-wetter-item ntv-panel-wetter-itemDesktop'>
<div className='ntv-panel-wetter-item_day'>Mo.</div>
<div className='ntv-panel-wetter-item_temp1'><span>9°</span>/4°</div>
</div>

</div>

</div>

</div>
    </div>
  
   </div>
 
  );


  }




export default Panel;
