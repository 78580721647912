import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Dummy4 from '../assets/img/dummy4.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'
import NoImg from '../../../../Images/noimg.jpg'

function ArtikelTeaserItems2({img, category, title, rate, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
    

     <div className='chef-article_teaser2-items'>
      
      <div className='chef-article_teaser2-items-img-container'>
      <img className='chef-article_teaser2-items-img' src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
      
   
      </div>

      <div className='chef-article_teaser2-items-text-container'>
      <Box className="chef-rating_container" sx={{ '& > legend': { mt: 2 } }}>
        <Rating className='chef-rating-star' size="small" name="read-only" value={1} max={1} readOnly/>
        <div className='chef-rating-value'>{rate}</div>
        </Box>
      <div className='chef-article_teaser2-items-desk-head'>
      {category}
      </div>

      <div className='chef-article_teaser2-items-desk-text'> {title}</div>

     
      <div className='chef-article_teaser2-items-text_author'></div>
      </div>

      
  </div>









    

   </>
 
  );


  }






export default ArtikelTeaserItems2;
