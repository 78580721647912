import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import GalaLogo from '../assets/logos/logo.svg'

import './styles.scss'


function Footer() {



  



  useEffect(()=>{


    
  
},[])






  return (

   <div className='chef-footer'>

<div className='chef-footer_container'>

<div className='chef-footer-text'>Folge uns</div>
<div className='chef-footer-icons_container'>
  

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M16.441 4.67a1.16 1.16 0 1 1-2.32 0 1.16 1.16 0 0 1 2.32 0m-1.444 5.188a4.864 4.864 0 1 1-9.727 0 4.864 4.864 0 0 1 9.727 0m-1.748 0a3.094 3.094 0 1 0-6.188 0 3.094 3.094 0 0 0 6.188 0m6.704-3.904a5.93 5.93 0 0 0-5.93-5.93H5.957A5.93 5.93 0 0 0 .02 5.954v8.092a5.93 5.93 0 0 0 5.936 5.93h8.091a5.93 5.93 0 0 0 5.931-5.93zm-1.95 8.004a4.07 4.07 0 0 1-4.068 4.07h-7.89a4.074 4.074 0 0 1-4.075-4.07V6.042a4.074 4.074 0 0 1 4.074-4.074h7.917a4.074 4.074 0 0 1 4.069 4.074z"></path></svg></svg>
  </div>

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M18.52 4.867c0-.088 0-.093-.096-.093q-.332-.002-.66-.057c-.796-.124-3.383-1.503-3.819-3.76a7 7 0 0 1-.106-.848c0-.103 0-.103-.102-.108h-3.109c-.203 0-.172-.026-.172.18v13.4q.007.259-.036.516c-.062.385-.2.754-.405 1.084s-.474.614-.79.836a2.8 2.8 0 0 1-2.188.45 4 4 0 0 1-.669-.227l-.05-.046c-.077-.062-.158-.114-.234-.17a2.84 2.84 0 0 1-1.007-1.19 2.9 2.9 0 0 1 .37-2.996 2.8 2.8 0 0 1 1.266-.897c.324-.12.67-.171 1.014-.15q.335.012.66.103c.076 0 .116 0 .121-.088v-.082c0-.806-.03-2.557-.035-2.557v-.692c0-.067-.03-.083-.086-.088a6.4 6.4 0 0 0-1.223-.026 6 6 0 0 0-1.653.357 6.1 6.1 0 0 0-2.242 1.436 6.3 6.3 0 0 0-1.816 5.061c.04.453.127.9.26 1.333a6.3 6.3 0 0 0 2.099 3.073q.146.137.324.228l.153.139q.238.186.507.32a6.17 6.17 0 0 0 4.067.568 6.25 6.25 0 0 0 3.54-2.117 6.27 6.27 0 0 0 1.492-4.132V6.953c0-.051-.03-.124.03-.155.06-.03.086.042.132.073a8 8 0 0 0 2.637 1.157c.55.133 1.113.202 1.679.206.177 0 .203 0 .203-.19-.016-.807-.056-2.966-.056-3.177"></path></svg></svg>
  </div>

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M7.458 10.079v9.627a.26.26 0 0 0 .152.233q.046.018.095.019h3.512a.24.24 0 0 0 .175-.074.25.25 0 0 0 .073-.178V9.92h2.542q.095 0 .167-.064a.25.25 0 0 0 .08-.162l.243-2.951a.25.25 0 0 0-.061-.192.24.24 0 0 0-.181-.081h-2.79v-2.1c0-.236.091-.461.255-.628a.86.86 0 0 1 .616-.26h1.96a.25.25 0 0 0 .175-.074.26.26 0 0 0 .072-.178V.294a.26.26 0 0 0-.074-.177.25.25 0 0 0-.173-.075H10.98a3.5 3.5 0 0 0-2.49 1.05A3.62 3.62 0 0 0 7.457 3.63v2.842H5.704a.25.25 0 0 0-.173.075.26.26 0 0 0-.074.177v2.951a.26.26 0 0 0 .074.175.25.25 0 0 0 .173.072h1.754z"></path></svg></svg>
  </div>

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M8.765 13.045c0 .058-.032.105-.046.158-.72 2.856-.8 3.518-1.543 4.85q-.529.954-1.192 1.814c-.047.067-.094.153-.196.129s-.117-.12-.127-.21a15.6 15.6 0 0 1-.159-2.637c.038-1.147.178-1.542 1.637-7.779a.5.5 0 0 0-.033-.262 4.45 4.45 0 0 1-.117-2.913c.66-2.123 3.03-2.29 3.44-.533.258 1.085-.416 2.504-.935 4.603-.43 1.733 1.571 2.966 3.273 1.7 1.575-1.167 2.187-3.961 2.07-5.941-.229-3.947-4.478-4.804-7.18-3.533-3.09 1.457-3.795 5.36-2.398 7.141.178.229.314.367.257.595-.093.357-.173.719-.266 1.076a.38.38 0 0 1-.213.27.37.37 0 0 1-.339-.018 3.24 3.24 0 0 1-1.29-.98c-1.182-1.495-1.524-4.456.042-6.96C5.19.839 8.42-.28 11.37.059c3.525.409 5.754 2.856 6.17 5.64.192 1.267.052 4.395-1.692 6.599-2.01 2.542-5.268 2.709-6.769 1.152a5 5 0 0 1-.313-.405"></path></svg></svg>
  </div>

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M19.98 7.486a4.5 4.5 0 0 0-1.279-3.145 4.33 4.33 0 0 0-3.088-1.302H4.367a4.33 4.33 0 0 0-3.088 1.302A4.5 4.5 0 0 0 0 7.486v5.028c0 1.18.46 2.31 1.28 3.145a4.33 4.33 0 0 0 3.087 1.303h11.246c1.158 0 2.269-.47 3.088-1.303a4.5 4.5 0 0 0 1.28-3.145zm-6.184 2.669L8.77 12.697c-.195.106-.884-.036-.884-.265v-5.22c0-.234.694-.376.889-.264l4.807 2.658c.2.117.419.412.214.549"></path></svg></svg>
  </div>

  <div className='chef-footer-icon'>
  <svg viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" fill="#595852"></path><svg viewBox="0 0 20 20" width="20" height="20" x="10" y="10" fill='#595852'><path  d="M17.004 2.906A9.844 9.844 0 0 0 9.994 0C4.53 0 .085 4.445.084 9.91a9.89 9.89 0 0 0 1.322 4.954L0 20l5.254-1.378a9.898 9.898 0 0 0 4.736 1.206h.004c5.462 0 9.908-4.446 9.91-9.91a9.85 9.85 0 0 0-2.9-7.011zm-7.01 15.248H9.99a8.226 8.226 0 0 1-4.192-1.148l-.3-.178-3.119.817.832-3.04-.195-.311a8.216 8.216 0 0 1-1.26-4.384c.002-4.541 3.697-8.236 8.241-8.236 2.2 0 4.268.858 5.824 2.416a8.187 8.187 0 0 1 2.41 5.827c-.002 4.542-3.697 8.237-8.237 8.237zm4.518-6.169c-.248-.124-1.465-.722-1.692-.805-.227-.083-.392-.124-.557.124-.165.248-.64.806-.784.97-.145.166-.29.187-.537.063-.247-.124-1.045-.386-1.991-1.23-.736-.656-1.233-1.467-1.378-1.715-.144-.248-.015-.382.109-.505.11-.111.247-.29.371-.434.124-.144.165-.248.248-.413.082-.165.041-.31-.02-.434-.063-.124-.558-1.342-.764-1.838-.201-.483-.406-.417-.557-.425-.145-.008-.31-.009-.475-.009a.91.91 0 0 0-.66.31c-.228.248-.867.847-.867 2.066 0 1.218.887 2.396 1.01 2.561.125.166 1.747 2.667 4.231 3.74a14.2 14.2 0 0 0 1.412.521c.593.19 1.133.162 1.56.099.475-.072 1.465-.6 1.67-1.178.207-.578.207-1.074.145-1.177-.062-.103-.227-.166-.475-.29h.001z"></path></svg></svg>
  </div>

</div>

<div className='chef-footer-links_container'>
  <div className='chef-footer-links'>
    <div className='chef-footer-links-title'>Unternehmen</div>
    <div className='chef-footer-link'>Presse</div>
    <div className='chef-footer-link'>Jobs</div>
    <div className='chef-footer-link'>Impressum</div>
    <div className='chef-footer-link'>AGB</div>
    <div className='chef-footer-link'>Meldung rechtswidrige Inhalte</div>
    <div className='chef-footer-link'>Verträge hier kündigen</div>
    <div className='chef-footer-link'>Datenschutz</div>
    <div className='chef-footer-link'>Datenschutz-Einstellungen</div>
    <div className='chef-footer-link'>Werben Sie bei uns</div>
    <div className='chef-footer-link'>Nutzungsbasierte Online Werbung</div>
  </div>

  <div className='chef-footer-links'>
    <div className='chef-footer-links-title'>Quicklinks</div>
    <div className='chef-footer-link'>Rezepte finden</div>
    <div className='chef-footer-link'>Was koche ich heute</div>
    <div className='chef-footer-link'>Was backe ich heute</div>
    <div className='chef-footer-link'>Magazin Übersicht</div>
    <div className='chef-footer-link'>Foren</div>
    <div className='chef-footer-link'>Videos Übersicht</div>

  </div>

  <div className='chef-footer-links'>
    <div className='chef-footer-links-title'>Newsletter</div>
    <div className='chef-footer-link'>Zum Newsletter anmelden</div>
    <div className='chef-footer-links-title' style={{marginTop:'20px'}}>Wir sind für Dich da</div>
    <div className='chef-footer-link'>FAQ</div>

  </div>

  <div className='chef-footer-links'>
    <div className='chef-footer-links-title'>Live Rezeptsuchen</div>
    <div className='chef-footer-link'>zwiebelsuppen auflauf rezepte, </div>
    <div className='chef-footer-link'>brezenknödel aus dampfgarer rezepte, </div>
    <div className='chef-footer-link'>puddingkuchen rezepte, quitten </div>
    <div className='chef-footer-link'>rezepte, plätzchen füllungen rezepte, </div>
    <div className='chef-footer-link'>kassler lachs rezepte, rinderleber </div>
    <div className='chef-footer-link'>rezepte, graupen rezepte, hot dog </div>
    <div className='chef-footer-link'>rezepte, chilli sin carne rezepte</div>

  </div>

</div>

</div>
   

   </div>
 
  );


  }




export default Footer;

